import React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Link from '@mui/material/Link';
import FormHelperText from '@mui/material/FormHelperText';


const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#008000',
    },
    secondary: {
      main: '#6495ed'
    }
  },
});

export default function ForgotPassword() {
  const [formErrors, setFormErrors] = useState({});
  const [signUpErrors, setSignUpErrors] = useState({});
  const [wait, setWait] = useState(false);
  const [successfullyMsg, setSuccessfullyMsg] = useState(false);

  const navigate = useNavigate();
  const location = useLocation()

  const token = localStorage.getItem('token')

  const resetPassword = (body) => {
    console.log(body);
    fetch('https://ddannytech-https.ddns.net:4001/reset-password-request', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(body)
    }).then(response => {
      if (response && response.ok) {
        setSuccessfullyMsg(true)
        setWait(false)
      }
      if (response && response.status === 401) {
        setWait(false)
        setSignUpErrors({message: 'Please enter the email address that is associated with your registered account.'})
      }
      if (response && response.status === 400) {
        setWait(false)
        setSignUpErrors({message: 'An server error has occurred, please try again.'})
      }
    }).catch(error => {
      console.error(error);
      setWait(false)
      setSignUpErrors({message: 'An server error has occurred, please try again.'})
    })
  }

  const validateField = (name, value) => {
    if (!value) return `${name.charAt(0).toUpperCase() + name.slice(1)} is required`;

    if (name === 'email') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value)) return 'Invalid email format';
    }

    return null;
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;
    setFormErrors({ ...formErrors, [name]: validateField(name, value) });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setWait(true)
    setSignUpErrors({})
    const data = new FormData(event.currentTarget);
    for (let name of data.keys()) {
      const error = validateField(name, data.get(name));
      if (error) {
        setFormErrors({ ...formErrors, [name]: error });
        setWait(false)
        return;
      }
    }
    const body = {
      email: data.get('email'),
    }
    resetPassword(body)
  };

  useEffect(() => {
    console.log(location.pathname.split('/')[1]);
  })

  return (
    <ThemeProvider theme={defaultTheme}>
      <Typography style={{ marginTop: "10vh", marginLeft: "30px" }}>
        <Link href="/signin" variant="body2" style={{ color: "#000", textDecoration: 'none' }}>
          <i class="bi bi-arrow-left-short" style={{ fontSize: '1.2rem' }}></i> <span style={{ fontSize: '1.2rem' }}>Return</span>
        </Link>
      </Typography>
      {!successfullyMsg ? <Container component="main" maxWidth="sm" style={{ paddingBottom: "10vh", minHeight: "80vh" }}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component='h1' variant='h4' sx={{ marginBottom: 2, textAlign: 'center', color: 'green' }}>
            New Password Request
          </Typography>

          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }} style={{ border: '1px solid', borderColor: formErrors.email ? '#ff0000' : 'green', borderRadius: '12px', padding: '30px', backgroundColor: '#ffffff' }}>
            <Typography component='h6' variant='h6' sx={{ marginBottom: 0, textAlign: 'justify', color: 'green' }}>
              Recover your account
            </Typography>
            <hr />
            <Grid container spacing={0} style={{ marginTop: '3vh' }}>
              <Typography component='h6' variant='h6' sx={{ marginBottom: 2, textAlign: 'justify', color: '#000' }}>
                Enter your email address to search for your account.
              </Typography>
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  error={!!formErrors.email}
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleBlur}
                  style={{ backgroundColor: '#fff' }}
                />
                {!!formErrors.email && (
                  <FormHelperText style={{ backgroundColor: '#ffffff', color: '#ff0000' }}>
                    {formErrors.email}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
            {
              signUpErrors.message && <div style={{ color: 'red', marginBottom: '5px', paddingTop: '25px', backgroundColor: '#fff' }}>{signUpErrors.message}</div>
            }
            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 3, mb: 2, fontWeight: 'bold', marginTop: "50px", backgroundColor: (wait || formErrors.email) ? '#e4e6eb' : '#1976d2' }}
              disabled={wait || formErrors.email}
            >
              {wait ? "Requesting a new password" : "Request a new password"}
            </Button>

          </Box>
        </Box>
      </Container>
        : <div style={{ minHeight: '70vh', width: '100%', display: 'flex', marginTop: '10vh', justifyContent: 'center' }}>
          <div style={{ textAlign: 'justify', width: '60%', height: '50%', backgroundColor: '#e9ecef', padding: '30px', borderRadius: '12px' }}>
            <h3 style={{ color: 'green', marginBottom: '10vh' }}>We have sent an email to your registered address with instructions on how to reset your password.</h3>
            <p>Please check your inbox and follow the steps provided to complete the password reset process. If you don’t see the email, please check your spam folder or request a new one.</p>
          </div>
        </div>
      }
    </ThemeProvider>
  );
}