import React, { Component } from 'react';
import '../styles/Product.css';
import { ReactComponent as VeganCert } from '../vegan-cert.svg';
import { ReactComponent as FreshMilk } from '../fresh-milk.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import BottomCar from './CustomizationBtn';
import allergenFree from '../allergen-free.png';

export default class Product extends Component {
  render() {
    const { product, productKey, addToCartProductsHandler, handleDivClick, loadingAddToCart, setLoadingAddToCart, index, handlerAddToWishList } = this.props
    const moneyFormat = {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    }

    return (
      <div className='product-container' key={productKey}>
        <div className='product-name'>
          <p onClick={() => handleDivClick(product)}>
            {product.name.toUpperCase()}
          </p>
        </div>
        <div className='img-container'>
          <div className='lazy-img-container' onClick={() => handleDivClick(product)}>
            <LazyLoadImage src={product.image} alt={product.name} className='product-img' />
          </div>
          <div className='product-certification'>
            <i class="bi bi-bookmark-heart" style={ product.onWishList ? { color: "#ff0000" } : {} } onClick={() => !product.onWishList && handlerAddToWishList({productID: product.id, quantity: 1})}></i>
            {
              product.product_type === "vegan" ? <VeganCert id="miIcono" />
                : <FreshMilk id="miIcono" />
            }
            {
              product.allergens === "none" &&
              <img src={allergenFree} alt='Allergen free'
              />
            }
          </div>
        </div>
        <div className='brand'>
          <p>
            {product.brand.toUpperCase()}
          </p>
        </div>
        <div className='price-container'>
          <span>
            <strong>
              {product.price.toLocaleString('en-US', moneyFormat)}
            </strong>
          </span>
        </div>
        <div className='btn-container'>
          <BottomCar id={product.name}
            loadingAddToCart={loadingAddToCart}
            index={index}
            onClick={() => {
              setLoadingAddToCart(index)
              addToCartProductsHandler(product.id, 1)
            }}
          />
        </div>
      </div>
    )
  }
}
