import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import AddressForm from './AddressForm';
import getCheckoutTheme from './getCheckoutTheme';
import Info from './Info';
import InfoMobile from './InfoMobile';
import PaymentForm from './PaymentForm';
import Review from './Review';
import ToggleColorMode from './ToggleColorMode';
import logo from '../img/logo.png';
import Loading from './Loading';

const steps = ['Shipping address', 'Payment details', 'Review your order'];

const logoStyle = {
  width: '66px',
  height: '56px',
  marginRight: '-8px',
};

const EmptyCartMessage = () => {
  return (
    <div style={{ width: "100%", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
      <div style={{ width: "50%", border: '2px solid #ccc', padding: '20px', backgroundColor: '#f9f9f9', textAlign: 'center' }}>
        <h2 style={{ paddingTop: '30px', marginBottom: "30px" }}>
          SHOPPING CART
        </h2>
        <img style={{ width: "30%" }} src='https://i.ibb.co/3Nf5XGG/shopping-cart-PNG50.png'></img>
        <h1 style={{ marginBottom: "50px" }}>
          Your Shopping Cart Is Currently Empty.
        </h1>
        <p style={{ fontSize: '18px' }}>
          Before proceeding to checkout, please ensure that you have added items to your shopping cart.
        </p>
        <p style={{ fontSize: '18px', textAlign: 'justify' }}>So, dear shopper, whether you're hunting for a delicious beyond meat, a sweet vegan snack, or a whimsical gift, <strong><a href='/'>let our home page be your compass</a></strong>.</p>
        <p style={{ fontSize: '18px' }}>Explore, click, and add to cart.</p>
      </div>
    </div>
  );
};

const UnauthorizedMsg = () => {
  const navigate = useNavigate()
  return (
    <div className="Unauthorized-msg-container" style={{ height: '100vh', width: '100%', display: 'flex', justifyContent: "center", alignItems: "center" }}>
      <div style={{ backgroundColor: "#f9f9f9", padding: "20px", border: "1px solid #ddd", borderRadius: "12px", textAlign: "center" }}>
        <p style={{ fontSize: "18px", color: "#333" }}>You must be logged in to view your wish list.</p>
        <p style={{ fontSize: "16px", color: "#666" }}>Please sign in to access your wish list:</p>
        <button onClick={() => navigate('/signin')} style={{ backgroundColor: "#00BBF9", borderRadius: "12px", marginTop: "15px", marginBottom: "20px", width: '80%', color: "#fff", border: "none", padding: "10px 20px", fontSize: "18px", fontWeight: "bold", cursor: "pointer", marginRight: "10px" }}>Sign In</button>
        <p><a href='/signup'>Don't have an account? Sign Up</a></p>
      </div>
    </div>
  )
}

function getStepContent(step, setAddressHandler, setRequiredHandler, required, payment, shoppingCartItems, address, subtotalPrice, handleCheck) {
  switch (step) {
    case 0:
      return <AddressForm setAddressHandler={setAddressHandler} setRequiredHandler={setRequiredHandler} stateRequied={required} step={step} handleCheck={handleCheck} />;
    case 1:
      return <PaymentForm payment={payment} />;
    case 2:
      return <Review shoppingCartItems={shoppingCartItems} address={address} payment={payment} subtotalPrice={subtotalPrice} />;
    default:
      throw new Error('Unknown step');
  }
}

export default function Checkout(props) {
  const [mode, setMode] = React.useState('light');
  const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  const checkoutTheme = createTheme(getCheckoutTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });
  const [activeStep, setActiveStep] = React.useState(0);
  const [subtotalPrice, setSubtotalPrice] = useState(0);
  const [address, setAddress] = useState();
  const [payment, setPayment] = useState({ name: "VISA", cardNumber: "4716407486132362", expDate: "6/2028", cvcNum: 790 })
  const [required, setRequired] = React.useState(false);
  const [orderNumber, setOrderNumber] = useState(0);
  const [orders, setOrders] = useState({})
  const [checked, setChecked] = useState(false);

  const { getCartItems, shoppingCartItems, auth, emptyCart, HandleEmptyCart } = props

  const setAddressHandler = (value) => {
    setAddress(value)
  }

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  const saveAddress = (objAddress) => {
    fetch('https://ddannytech-https.ddns.net:4001/shipping-address', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(objAddress),
      mode: 'cors',
    }).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
    }).catch(error => {
      console.log(error);
    })

  }

  const handleUpdateAddress = () => {
    console.log(address);
    let objAddress = Object.fromEntries(address.split(';').map(item => item.split(':').map(part => part.replace(/'/g, ''))))
    console.log(objAddress);
    saveAddress(objAddress)
  }

  const handleNext = () => {
    setActiveStep(activeStep + 1);
    if (activeStep === 2) {
      placeOrder()
    }
    if (checked) {
      handleUpdateAddress()
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const setRequiredHandler = (value) => {
    setRequired(value);
  };

  const navigate = useNavigate()

  const token = localStorage.getItem('token');

  const setPlaceOrder = useCallback((body) => {
    const fetchOrder = () => {
      fetch('https://ddannytech-https.ddns.net:4001/create-order', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(body),
        mode: 'cors',
      }).then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        response.json().then(data => setOrderNumber(data.message))
      }).catch(error => {
        console.log(error);
        setTimeout(fetchOrder, 6000);
      });
    };

    fetchOrder();
  }, []);

  const getOrders = useCallback(() => {
    fetch('https://ddannytech-https.ddns.net:4001/show-orders', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${token}`
      },
      mode: 'cors',
    }).then(response => {
      if (response.status === 304) {
        console.log('response');
      }
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      response.json().then(data => setOrders(data))
    }).catch(error => {
      console.error('There has been a problem with your fetch operation:', error);
    });
  }, []);

  const placeOrder = () => {
    const body = {
      cartItem: shoppingCartItems.map(item => ({
        productID: item.productID,
        quantity: item.quantity
      })),
      payment: payment,
      address: address
    }

    setPlaceOrder(body)
  }

  const setSubtotalPriceHandler = () => {
    let price = 0
    for (let i = 0; i < shoppingCartItems.length; i++) {
      price = (shoppingCartItems[i].price * shoppingCartItems[i].quantity) + price
    }
    setSubtotalPrice(price)
  };

  const handleOrders = () => {
    getOrders()
    navigate('/view-orders')
  }

  const handleCheck = (event) => {
    setChecked(event);
    console.log(event);
  };

  const checkAuth = useCallback(() => {
    fetch(`https://ddannytech-https.ddns.net:4001/check-auth`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      },
      mode: 'cors',
    })
      .then((response) => {
        if (response.status === 401) {
          return navigate('/signin')
        }
        if (response.status === 403 || response.statusText === 'Forbidden') {
          return navigate('/emailVerification')
        }
        if (response.status === 402) {
          return navigate('/VerificationLoginCode')
        }
        return null
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, [navigate]);

  useEffect(() => {
    setSubtotalPriceHandler()
    HandleEmptyCart()
  }, [shoppingCartItems])

  useEffect(() => {
    getCartItems();
  }, [token]);

  useEffect(() => {
    checkAuth()
  }, [])

  return (
    <ThemeProvider theme={showCustomTheme ? checkoutTheme : defaultTheme}>
      <CssBaseline />
      {shoppingCartItems.length > 0 ?
        <Grid container sx={{ height: { xs: '100%', sm: '100dvh' } }}>
          <Grid
            item
            xs={12}
            sm={5}
            lg={4}
            sx={{
              display: { xs: 'none', md: 'flex' },
              flexDirection: 'column',
              backgroundColor: 'background.paper',
              borderRight: { sm: 'none', md: '1px solid' },
              borderColor: { sm: 'none', md: 'divider' },
              alignItems: 'start',
              pt: 4,
              px: 10,
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'end',
                height: 150,
              }}
            >
              <Button
                startIcon={<ArrowBackRoundedIcon />}
                component="a"
                href="/shopping-cart-view"
                sx={{ ml: '-8px' }}
              >
                Back to
                <img
                  src={logo}
                  style={logoStyle}
                  alt="logo"
                />
              </Button>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                width: '100%',
                maxWidth: 500,
              }}
            >
              <Info shoppingCartItems={shoppingCartItems} totalPrice={subtotalPrice} />
            </Box>
          </Grid>
          <Grid
            item
            sm={12}
            md={7}
            lg={8}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '100%',
              width: '100%',
              backgroundColor: { xs: 'transparent', sm: 'background.default' },
              alignItems: 'start',
              pt: { xs: 2, sm: 4 },
              px: { xs: 2, sm: 10 },
              gap: { xs: 4, md: 8 },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: { sm: 'space-between', md: 'flex-end' },
                alignItems: 'center',
                width: '100%',
                maxWidth: { sm: '100%', md: 600 },
              }}
            >
              <Box
                sx={{
                  display: { xs: 'flex', md: 'none' },
                  flexDirection: 'row',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <Button
                  startIcon={<ArrowBackRoundedIcon />}
                  component="a"
                  href="/"
                  sx={{ alignSelf: 'start' }}
                >
                  Back to
                  <img
                    src={logo}
                    style={logoStyle}
                    alt="Logo"
                  />
                </Button>
                <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} />
              </Box>
              <Box
                sx={{
                  display: { xs: 'none', md: 'flex' },
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                  flexGrow: 1,
                  height: 150,
                }}
              >
                <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} />
                <Stepper
                  id="desktop-stepper"
                  activeStep={activeStep}
                  sx={{
                    width: '100%',
                    height: 40,
                  }}
                >
                  {steps.map((label) => (
                    <Step
                      sx={{
                        ':first-child': { pl: 0 },
                        ':last-child': { pr: 0 },
                      }}
                      key={label}
                    >
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>
            </Box>
            <Card
              sx={{
                display: { xs: 'flex', md: 'none' },
                width: '100%',
              }}
            >
              <CardContent
                sx={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  ':last-child': { pb: 2 },
                }}
              >
                <div>
                  <Typography variant="subtitle2" gutterBottom>
                    Selected products
                  </Typography>
                  <Typography variant="body1">
                    ${Number(subtotalPrice.toFixed(2))}
                  </Typography>
                </div>
                <InfoMobile shoppingCartItems={shoppingCartItems} totalPrice={subtotalPrice} />
              </CardContent>
            </Card>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                width: '100%',
                maxWidth: { sm: '100%', md: 600 },
                maxHeight: '720px',
                gap: { xs: 5, md: 'none' },
              }}
            >
              <Stepper
                id="mobile-stepper"
                activeStep={activeStep}
                alternativeLabel
                sx={{ display: { sm: 'flex', md: 'none' } }}
              >
                {steps.map((label) => (
                  <Step
                    sx={{
                      ':first-child': { pl: 0 },
                      ':last-child': { pr: 0 },
                      '& .MuiStepConnector-root': { top: { xs: 6, sm: 12 } },
                    }}
                    key={label}
                  >
                    <StepLabel
                      sx={{ '.MuiStepLabel-labelContainer': { maxWidth: '70px' } }}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
              {activeStep === steps.length ? orderNumber > 0 ? (
                <Stack spacing={2} useFlexGap>
                  <Typography variant="h1" color={'green'}><i class="bi bi-rocket-takeoff"></i></Typography>
                  <Typography variant="h5" color={'green'} marginBottom={'20px'}>Order Successfully Placed!</Typography>
                  <Typography variant="body1" color="text.secondary" marginBottom={'20px'}>
                    Your order number is
                    <strong>&nbsp;#{orderNumber}</strong>. We have emailed your order
                    confirmation and will update you once its shipped.
                  </Typography>
                  {
                    Object.keys(orders).length > 0 && <div>Working</div>
                  }
                  <Button
                    variant="contained"
                    sx={{
                      alignSelf: 'start',
                      width: { xs: '100%', sm: 'auto' },
                    }}
                    onClick={handleOrders}
                  >
                    Go to my orders
                  </Button>
                </Stack>
              ) : (
                <div>
                  <Typography variant="body1" color="text.secondary">
                    <CircularProgress color="success" /> Processing Your Order, please wait...
                  </Typography>
                  <br />
                  <Typography variant="body1" color="text.secondary" style={{ textAlign: 'justify' }}>
                    Do not close this page while your order is being processed. We’re currently fine-tuning the details of your order with care. Hold on for just a moment while we wrap up the final touches. Your patience is greatly appreciated, and we’ll update you shortly once everything is set. Thank you for choosing us!
                  </Typography>
                </div>
              ) : (
                <React.Fragment>
                  {getStepContent(activeStep, setAddressHandler, setRequiredHandler, required, payment, shoppingCartItems, address, subtotalPrice, handleCheck)}
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: { xs: 'column-reverse', sm: 'row' },
                      justifyContent: activeStep !== 0 ? 'space-between' : 'flex-end',
                      alignItems: 'end',
                      flexGrow: 1,
                      gap: 1,
                      pb: { xs: 12, sm: 0 },
                      mt: { xs: 2, sm: 0 },
                      mb: '60px',
                    }}
                  >
                    {activeStep !== 0 && (
                      <Button
                        startIcon={<ChevronLeftRoundedIcon />}
                        onClick={handleBack}
                        variant="text"
                        sx={{
                          display: { xs: 'none', sm: 'flex' },
                        }}
                      >
                        Previous
                      </Button>
                    )}

                    {activeStep !== 0 && (
                      <Button
                        startIcon={<ChevronLeftRoundedIcon />}
                        onClick={handleBack}
                        variant="outlined"
                        fullWidth
                        sx={{
                          display: { xs: 'flex', sm: 'none' },
                        }}
                      >
                        Previous
                      </Button>
                    )}

                    <Button
                      variant="contained"
                      endIcon={<ChevronRightRoundedIcon />}
                      onClick={handleNext}
                      sx={{
                        width: { xs: '100%', sm: 'fit-content' },
                      }}
                      disabled={required}
                    >
                      {activeStep === steps.length - 1 ? 'Place order' : 'Next'}
                    </Button>
                  </Box>
                </React.Fragment>
              )}
            </Box>
          </Grid>
        </Grid>
        : (emptyCart && auth) ? <EmptyCartMessage />
        : (!auth) ? <UnauthorizedMsg /> 
        : <Loading />
      }

    </ThemeProvider>
  );
}
