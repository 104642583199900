import React, { useState, useEffect, useCallback } from 'react'
import { useLocation } from 'react-router-dom';
import Products from './Products';

export default function SearchedProducts({ addToCartProductsHandler, loadingAddToCart, setLoadingAddToCart, handlerAddToWishList, wishList }) {
  const [products, setProducts] = useState([])
  const [search, setSearch] = useState('')

  const location = useLocation()

  const searchProducts = useCallback((search) => {
    fetch(`https://ddannytech-https.ddns.net:4001/search/${search}`, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
      mode: 'cors',
    })
      .then((response) => {
        return response.json()
      })
      .then((searchedProducts) => {
        return setProducts(searchedProducts)
      })
      .catch((error) => console.error('Error:', error))
  }, [setProducts]);

  const handleOnWishList = () => {
    if (wishList.length > 0) {
      let wishListSet = new Set(wishList.map(item => item.productID))
      let productsW = products.map(product => ({
        ...product,
        onWishList: wishListSet.has(product.id)
      }))
      setProducts(productsW)
    } else {
      let productW = products.map(product => ({
        ...product,
        onWishList: false
      }))
      setProducts(productW)
    }
  }

  useEffect(() => {
    let route = location.pathname.split('/')[2]
    searchProducts(route)
    if (products.length > 0) {
      handleOnWishList()
    }
  }, [])

  useEffect(() => {
    let route = location.pathname.split('/')[2]
    searchProducts(route)
  }, [location.pathname])

  useEffect(() => {
    if (products.length > 0) {
      handleOnWishList()
    }
  },[products])

  return (
    <div className='main-container' style={{ minHeight: '100vh' }}>
      {
        products.length > 0 &&
        <Products
          products={products}
          addToCartProductsHandler={addToCartProductsHandler}
          loadingAddToCart={loadingAddToCart}
          setLoadingAddToCart={setLoadingAddToCart}
          handlerAddToWishList={handlerAddToWishList}
        />
      }
    </div>
  )
}
