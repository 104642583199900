import * as React from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export default function Review({ shoppingCartItems, address, payment, subtotalPrice }) {

  const [addressInfo, setAddressInfo] = React.useState({})
  const [totalItems, setTotalItems] = React.useState(0)

  const getAddress = () => {
    let tempAddressInfo = {};
    address.split(';').forEach(element => {
      let pair = element.split(':');
      let key = pair[0].replace(/'/g, '');
      let value = pair[1].replace(/'/g, '');
      tempAddressInfo[key] = value;
    });
    setAddressInfo(tempAddressInfo);
  }

  const setItems = () => {
    const total = shoppingCartItems.reduce((acc, item) => acc + item.quantity, 0);
    setTotalItems(total);
  }

  React.useEffect(() => {
    setItems()
  }, [])

  React.useEffect(() => {
    getAddress()
  }, [])

  return (
    <Stack spacing={2}>
      <List disablePadding>
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary="Products" secondary={totalItems + " selected"} />
          <Typography variant="body2">${subtotalPrice.toFixed(2)}</Typography>
        </ListItem>
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary="Shipping" secondary="Plus taxes" />
          <Typography variant="body2">$9.99</Typography>
        </ListItem>
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary="Total" />
          <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
            ${(subtotalPrice + 9.99).toFixed(2)}
          </Typography>
        </ListItem>
      </List>
      <Divider />
      <Stack
        direction="column"
        divider={<Divider flexItem />}
        spacing={2}
        sx={{ my: 2 }}
      >
        <div>
          <Typography variant="subtitle2" gutterBottom>
            Shipment details
          </Typography>
          <Typography gutterBottom>{addressInfo['first-name']} {addressInfo['last-name']}</Typography>
          {
            addressInfo &&
            Object.entries(addressInfo)
              .filter(([key]) => key !== 'first-name' && key !== 'last-name')
              .map(([key, value]) => (
                <Typography color="text.secondary" gutterBottom key={key}>
                  {`${value}`}
                </Typography>
              ))
          }
        </div>
        <div>
          <Typography variant="subtitle2" gutterBottom>
            Payment details
          </Typography>
          <Grid container>
          <React.Fragment key={payment.name+'method'}>
                <Stack
                  direction="row"
                  spacing={1}
                  useFlexGap
                  sx={{ width: '100%', mb: 1 }}
                >
                  <Typography variant="body1" color="text.secondary">
                    Card type:
                  </Typography>
                  <Typography variant="body2">{payment.name}</Typography>
                </Stack>
              </React.Fragment>
              <React.Fragment key={payment.name+"name"}>
                <Stack
                  direction="row"
                  spacing={1}
                  useFlexGap
                  sx={{ width: '100%', mb: 1 }}
                >
                  <Typography variant="body1" color="text.secondary">
                    Card holder:
                  </Typography>
                  <Typography variant="body2">{payment.name}</Typography>
                </Stack>
              </React.Fragment>
              <React.Fragment key={payment.name+'row'}>
                <Stack
                  direction="row"
                  spacing={1}
                  useFlexGap
                  sx={{ width: '100%', mb: 1 }}
                >
                  <Typography variant="body1" color="text.secondary">
                    Card number:
                  </Typography>
                  <Typography variant="body2">{'xxxx-xxxx-xxxx-'+payment.cardNumber.slice(-4)}</Typography>
                </Stack>
              </React.Fragment>

              <React.Fragment key={payment.name+'date'}>
                <Stack
                  direction="row"
                  spacing={1}
                  useFlexGap
                  sx={{ width: '100%', mb: 1 }}
                >
                  <Typography variant="body1" color="text.secondary">
                    Expiry date:
                  </Typography>
                  <Typography variant="body2">{payment.expDate}</Typography>
                </Stack>
              </React.Fragment>
          </Grid>
        </div>
      </Stack>
    </Stack>
  );
}
