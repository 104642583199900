import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { blue } from '@mui/material/colors';

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(blue[500]),
  backgroundColor: '#00BBF9',
  fontSize: 16,
  borderRadius: 18,
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: blue[900],
    
  },
  width: '99%',
  textAlign: 'center'
}));

export default function CustomizedButton({ onClick }) {
  return (
    <Stack spacing={1} direction="row" alignItems='center' justifyContent='center' >
      <ColorButton variant="contained" onClick={onClick}>
        Proceed to pay
      </ColorButton>
    </Stack>
  );
}