import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/ShoppingCart.css'
import CustomizedButton from './ProceedToPayBtn';
import Loading from './Loading';

const EmptyCartMessage = () => {

  const portrait = {
    width: "80%", border: 'none', padding: '20px', backgroundColor: '#f0f8ff', textAlign: 'center',
  }

  return (
    <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", marginTop: "4vh", borderRadius: '12px' }}>
      <div style={ portrait }>
        <img style={{ width: "30%" }} src='https://i.ibb.co/3Nf5XGG/shopping-cart-PNG50.png'></img>
        <h2 style={{ marginBottom: "50px" }}>
          Your Shopping Cart Is Currently Empty.
        </h2>
        <p style={{ fontSize: '18px', textAlign: 'justify' }}>Dear custumer, whether you're hunting for a delicious beyond meat, a sweet vegan snack, or a whimsical gift, let our home page be your compass.</p>
        <p style={{ fontSize: '18px', textAlign: 'justify' }}>Start shopping to fill it up!.</p>
      </div>
    </div>
  );
};

const ShoppingCart = (props) => {

  const [totalItems, setTotalItems] = useState(0);

  const { auth, show, shoppingCartItems, setShoppingCartItems, addToCart, HandlerDeleteCartItem, toggleShoppingCart, emptyCart } = props;

  const navigate = useNavigate()

  const incrementQuantity = (productID) => {
    let quantityChanged = false;
    console.log('increment');
    const newItems = shoppingCartItems.map((item) => {
      if (item.productID === productID && item.quantity < 25) {
        quantityChanged = true;
        return { ...item, quantity: item.quantity + 1};
      } else {
        return item;
      }
    }); 
    if (quantityChanged) {
      setShoppingCartItems(newItems);
      addToCart(productID, newItems.find(item => item.productID === productID).quantity);
    }
  };
  
  const decrementQuantity = (productID) => {
    let quantityChanged = false;
    const newItems = shoppingCartItems.map(item => {
      if (item.productID === productID && item.quantity > 1) {
        quantityChanged = true;
        return { ...item, quantity: item.quantity - 1};
      } else {
        return item;
      }
    }); 
    if (quantityChanged) {
      setShoppingCartItems(newItems);
      const productIndex = newItems.findIndex(item => item.productID === productID);
      addToCart(productID, newItems.find(item => item.productID === productID).quantity, productIndex);
    }
  };

  const updateQuantity = (productID, newQuantity, index) => {
    newQuantity = Number(newQuantity);
    let errorMsg = document.getElementById(`error-msg-${index}`)

    if (newQuantity === 0) {
      newQuantity = 1
    }

    if (newQuantity > 25) {
      errorMsg.innerText = 'Please note that you cannot add more than 25 units of the same product at once.'
      //setErrorMsg('Please note that you cannot add more than 25 units of the same product at once.')
      newQuantity = 25
    }

    if (errorMsg && errorMsg.innerText) {
      setTimeout(function() {
        errorMsg.innerText = '';
    }, 5000);
    }
    

    const newItems = shoppingCartItems.map(item => {
      if (item.productID === productID) {
        return { ...item, quantity: newQuantity };
      } else {
        return item;
      }
    }); 
    setShoppingCartItems(newItems);
    //addToCart(productID, newQuantity);
  };

  const updateCartItem = (productID, quantity) => {
    addToCart(productID, quantity)
  }

  const goToShoppingCart = () => {
    toggleShoppingCart()
    navigate('/shopping-cart-view')
  }

  const handleClick = () => {
    toggleShoppingCart()
    navigate('/shopping-cart-view')
  }

  const setItems = () => {
    const total = shoppingCartItems.reduce((acc, item) => acc + item.quantity, 0);
    setTotalItems(total);
  }

  useEffect(() => {
    setItems()
  }, [])

  useEffect(() => {
    setItems()
  }, [HandlerDeleteCartItem])
  
    return (
      <div className={`shopping-cart ${show ? 'show' : ''}`}>
        <div className='cart-title-container'>
          <h2 style={{ cursor: 'pointer', marginLeft: '10px', marginBottom: '15px', color: '#6495ed' }} onClick={() => shoppingCartItems.length > 0 && goToShoppingCart}>Shopping cart</h2>
          <p style={{ marginLeft: '10px' }} >{shoppingCartItems.length > 1 ? `You have ${shoppingCartItems.length} products listed in your cart` : shoppingCartItems.length === 1 && 'You have 1 product listed in your cart'}</p>
        </div>
        {shoppingCartItems.length > 0 ? (
          shoppingCartItems.map((item, index) => (
            <div className='cart-item-container' key={index}>
              <div className='items-container'>
                <div className='cart-img-container'>
                  <img className='cart-item-img' src={item.image} alt={item.name} />
                </div>
                <div className='cart-item-details-container'>
                  <p className='cart-item-name'>{item.name.toUpperCase()}</p>
                  <p className='cart-item-price'>${item.price.toFixed(2)}</p>
                </div>
                <div className='cart-item-handler'>
                  <div className='cart-item-quantity'>
                    <i className="bi bi-plus-circle" 
                      style={{color: 'green', fontSize: '1.5rem', cursor: 'pointer'}}
                      onClick={() => incrementQuantity(item.productID)}
                    ></i>
                    <input type='number' value={item.quantity} onChange={(e) => updateQuantity(item.productID, Number(e.target.value), index)} onBlur={(e) => updateCartItem(item.productID, Number(e.target.value))}></input>
                    <i className="bi bi-dash-circle" 
                      style={{color: 'red', fontSize: '1.5rem', cursor: 'pointer'}}
                      onClick={() => decrementQuantity(item.productID)}
                    ></i>
                  </div>
                  <div className='to-pay-delete'>
                    <span className='subtotal'>${parseFloat((item.price * item.quantity)).toFixed(2)}</span>
                    <i className="bi bi-trash-fill" style={{color: 'red', fontSize: '2rem', cursor: 'pointer'}} onClick={() => {return HandlerDeleteCartItem( item.cartID, item.productID)}}></i>
                  </div>
                </div>
              </div>
              <div id={'error-msg-' + index} style={{ marginRight: "15px", position: "relative", top: "-10px", color: "red", textAlign: "end", fontSize: "14px" }}></div>
            </div>
          ))
        )
          : !auth ?
            <div style={{ backgroundColor: "#f9f9f9", padding: "20px", border: "1px solid #ddd", borderRadius: "12px", textAlign: "center" }}>
              <p style={{ fontSize: "18px", color: "#333" }}>You must be logged in to view your shopping cart.</p>
              <p style={{ fontSize: "16px", color: "#666" }}>Please sign in to access your cart:</p>
              <button onClick={() => {toggleShoppingCart(); navigate('/signin')}} style={{ backgroundColor: "#00BBF9", borderRadius: "12px", marginTop: "15px", marginBottom: "20px", width: '80%', color: "#fff", border: "none", padding: "10px 20px", fontSize: "18px", fontWeight: "bold", cursor: "pointer", marginRight: "10px" }}>Sign In</button>
              <p><a href='/signup'>Don't have an account? Sign Up</a></p>
            </div>
          : (emptyCart && auth) ?  <EmptyCartMessage /> 
          : <Loading />}
        {
          shoppingCartItems.length > 0 && 
          <div className='proceed-to-pay-container' style={{ width: '100%', marginBottom: '30px', marginTop: "40px" }}>
            <CustomizedButton onClick={handleClick} />
          </div>
        }
      </div>
    );
}

export default ShoppingCart;
