import React, { Component } from 'react'
import milkAllergen from '../icons/milk_allergens.png'
import soyAllergen from '../icons/soy_allergens.png'
import wheatAllergen from '../icons/wheat_allergens.png'
import peanutsAllergen from '../icons/peanuts_allergens.png'
import crustaceanAllergen from '../icons/crustacean_allergens.png'
import allergenFree from '../icons/allergen-free.png'
import eggAllergen from '../icons/egg_allergens.png'
import sesameAllergen from '../icons/sesame_allergens.png'
import mustardAllergen from '../icons/mustard_allergens.png'
import almondsAllergen from '../icons/almonds_allergens.png'
import '../styles/ProductSpec.css'

export default class
    extends Component {
    render() {
        const { product } = this.props;
        const allergensIcons = {
            milk: milkAllergen,
            soy: soyAllergen,
            wheat: wheatAllergen,
            oats: wheatAllergen,
            crustacean: crustaceanAllergen,
            peanuts: peanutsAllergen,
            egg: eggAllergen,
            sesame: sesameAllergen,
            mustard: mustardAllergen,
            almonds: almondsAllergen
        }
        const allergensArr = product.allergens.toLowerCase().split(',').map(s => s.trim())
        const gridStyle = {
            maxWidth: "100%",
            display: "grid",
            gridTemplateColumns: `repeat(${allergensArr.length}, 1fr)`,
            color: "#ffff00",
            alignItems: "center",
            justifyItems: "center"
        }
        const flexStyle = {
            height: "150px",
            display: "flex",
            justifyContent: 'center',
        }
        const flexImg = {
            height: "100%"
        }
        const gridImg = {
            maxWidth: "100%",
            maxHeight: "80%"
        }
        let style;
        if (allergensArr.length > 1) {
            style = gridStyle;
        } else {
            if (product.allergens === "those with legume allergies may have a reaction to the pea protein") {
                style = {
                    height: "100%",
                    display: "flex",
                    justifyContent: 'center'
                }
            } else {
                style = flexStyle
            }
        }
        return (
            <div className='product-specs'>
                <div className='allergens-container'>
                    <h3 className='allergen-tittle'>Allergens:</h3>
                    <div className='allergen-content' style={style}>
                        {
                            allergensArr.length === 1 ? product.allergens === "none"
                                ? <img src={allergenFree} style={{ maxHeight: "80%" }} alt="Allergen Free" />
                                : product.allergens === "those with legume allergies may have a reaction to the pea protein" ? <span>{product.allergens.toUpperCase()}</span>
                                    : <img src={allergensIcons[allergensArr[0]]} alt={allergensIcons[allergensArr[0]]} style={flexImg}/>
                                : allergensArr.map((allergen) =>
                                    <img src={allergensIcons[allergen]} alt={allergen} style={gridImg} />
                                )
                        }
                    </div>
                </div>
                <div className='ingredients-container'>
                    <h3>Ingredients:</h3>
                    <div className='ingredients' >
                        <p>
                            {product.ingredients.toUpperCase()}
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}
