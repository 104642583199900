import * as React from 'react';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import OutlinedInput from '@mui/material/OutlinedInput';
import { styled } from '@mui/system';

const FormGrid = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export default function AddressForm({ setAddressHandler, setRequiredHandler, stateRequied, step, handleCheck }) {

  const [values, setValues] = React.useState(JSON.parse(localStorage.getItem('formValues')) || {});

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  React.useEffect(() => {
    const valuesString = Object.entries(values)
      .map(([key, value]) => `'${key}':'${value}'`)
      .join(';');
    setAddressHandler(valuesString);

    const requiredFields = ['firstName', 'lastName', 'address1', 'city', 'state', 'zip', 'country'];
    setRequiredHandler(requiredFields.some(field => !values[field]));

    localStorage.setItem('formValues', JSON.stringify(values));
  }, [values]);

  React.useEffect(() => {
    const storedValues = JSON.parse(localStorage.getItem('formValues'));
    if (storedValues) {
      const valuesString = Object.entries(storedValues)
        .map(([key, value]) => `'${key}':'${value}'`)
        .join(';');
      setAddressHandler(valuesString);
    }
  }, []);

  return (
    <Grid container spacing={3}>
      <FormGrid item xs={12} md={6}>
        <FormLabel htmlFor="firstName" required>
          First name
        </FormLabel>
        <OutlinedInput
          id="firstName"
          name="firstName"
          type="name"
          placeholder="John"
          autoComplete="first name"
          required
          onChange={handleChange}
          value={values['firstName'] || ''}
        />
      </FormGrid>

      <FormGrid item xs={12} md={6}>
        <FormLabel htmlFor="lastName" required>
          Last name
        </FormLabel>
        <OutlinedInput
          id="lastName"
          name="lastName"
          type="lastName"
          placeholder="Snow"
          autoComplete="last name"
          required
          onChange={handleChange}
          value={values['lastName'] || ''}
        />
      </FormGrid>

      <FormGrid item xs={12}>
        <FormLabel htmlFor="address1" required>
          Address line 1
        </FormLabel>
        <OutlinedInput
          id="address1"
          name="address1"
          type="address1"
          placeholder="Street name and number"
          autoComplete="shipping address-line1"
          required
          onChange={handleChange}
          value={values['address1'] || ''}
        />
      </FormGrid>

      <FormGrid item xs={12}>
        <FormLabel htmlFor="address2">Address line 2</FormLabel>
        <OutlinedInput
          id="address2"
          name="address2"
          type="address2"
          placeholder="Apartment, suite, unit, etc. (optional)"
          autoComplete="shipping address-line2"
          required
          onChange={handleChange}
          value={values['address2'] || ''}
        />
      </FormGrid>

      <FormGrid item xs={6}>
        <FormLabel htmlFor="city" required>
          City
        </FormLabel>
        <OutlinedInput
          id="city"
          name="city"
          type="city"
          placeholder="New York"
          autoComplete="City"
          required
          onChange={handleChange}
          value={values['city'] || ''}
        />
      </FormGrid>

      <FormGrid item xs={6}>
        <FormLabel htmlFor="state" required>
          State
        </FormLabel>
        <OutlinedInput
          id="state"
          name="state"
          type="state"
          placeholder="NY"
          autoComplete="State"
          required
          onChange={handleChange}
          value={values['state'] || ''}
        />
      </FormGrid>

      <FormGrid item xs={6}>
        <FormLabel htmlFor="zip" required>
          Zip / Postal code
        </FormLabel>
        <OutlinedInput
          id="zip"
          name="zip"
          type="zip"
          placeholder="12345"
          autoComplete="shipping postal-code"
          required
          onChange={handleChange}
          value={values['zip'] || ''}
        />
      </FormGrid>

      <FormGrid item xs={6}>
        <FormLabel htmlFor="country" required>
          Country
        </FormLabel>
        <OutlinedInput
          id="country"
          name="country"
          type="country"
          placeholder="United States"
          autoComplete="shipping country"
          required
          onChange={handleChange}
          value={values['country'] || ''}
        />
      </FormGrid>
      <FormGrid item xs={12}>
        <FormControlLabel
          control={<Checkbox name="saveAddress" value="yes" onChange={(event) => handleCheck(event.target.checked)} />}
          label="Use this address for payment details"
        />
      </FormGrid>
      <div className='message' style={{ marginTop: "20px", color: "red", width: "100%", paddingLeft: '24px' }}>
        <span>{stateRequied && "Please ensure all required fields (*) are filled out in order to proceed to the next form."}</span>
      </div>
    </Grid>
  );
}
