import React, { useState, useEffect, Component } from 'react';
import Pagination from '@mui/material/Pagination';
import { useNavigate } from 'react-router-dom';
import '../styles/Products.css'
import '../styles/Product.css';
import { ReactComponent as VeganCert } from '../vegan-cert.svg';
import { ReactComponent as FreshMilk } from '../fresh-milk.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import CartIconBtn from './CartIconBtn';
import allergenFree from '../allergen-free.png';
import WishListBtn from './WishListBtn';
import Loading from './Loading';

function EmptyWishListMsg() {
  const navigate = useNavigate()
  return (
    <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", marginTop: "6vh", borderRadius: '12px' }}>
      <div style={{ width: "70%", border: 'none', padding: '20px', backgroundColor: '#f0f8ff', textAlign: 'center' }}>
        <img style={{ width: "30%" }} src='https://i.ibb.co/VpW8T28/happy-online-shopping-add-to-wishlist-illustration-vector-Photoroom-png-Photoroom.png'></img>
        <h2 style={{ marginBottom: "50px" }}>
          Your Wishlist is Empty! 😢
        </h2>
        <p style={{ fontSize: '18px', textAlign: 'justify' }}>But don’t worry! Our store is full of amazing vegan products waiting for you. Explore our selection and add your favorites to the Wishlist.</p>
        <p style={{ fontSize: '18px', textAlign: 'justify' }}>Happy shopping!.</p>
      </div>
    </div>
  )
};

const UnauthorizedMsg = () => {
  const navigate = useNavigate()
  return (
    <div className="Unauthorized-msg-container" style={{ height: '100vh', width: '100%', display: 'flex', justifyContent: "center", alignItems: "center" }}>
      <div style={{ backgroundColor: "#f9f9f9", padding: "20px", border: "1px solid #ddd", borderRadius: "12px", textAlign: "center" }}>
        <p style={{ fontSize: "18px", color: "#333" }}>You must be logged in to view your wish list.</p>
        <p style={{ fontSize: "16px", color: "#666" }}>Please sign in to access your wish list:</p>
        <button onClick={() => navigate('/signin')} style={{ backgroundColor: "#00BBF9", borderRadius: "12px", marginTop: "15px", marginBottom: "20px", width: '80%', color: "#fff", border: "none", padding: "10px 20px", fontSize: "18px", fontWeight: "bold", cursor: "pointer", marginRight: "10px" }}>Sign In</button>
        <p><a href='/signup'>Don't have an account? Sign Up</a></p>
      </div>
    </div>
  )
}

export default function WishList(props) {
  const { products, showProductHandler, loadingAddToCart, setLoadingAddToCart, getWishListItems, getCartItems, shoppingCartItems, auth, emptyWishList } = props;
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [infMsg, setInfMsg] = useState('')
  const token = localStorage.getItem('token')

  const deleteWishListItem = (body) => {
    fetch('https://ddannytech-https.ddns.net:4001/wish-list', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
      },
      mode: 'cors',
      body: JSON.stringify(body)
    }).then(response => {
      if (response && response.ok) {
        getWishListItems()
      }
    }).catch(error => console.log(error))
  };

  const moveToShoppingCart = (body) => {
    fetch('https://ddannytech-https.ddns.net:4001/move-to-shopping-cart', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
      },
      mode: 'cors',
      body: JSON.stringify(body)
    }).then(response => {
      if (response && response.ok) {
        getWishListItems()
        getCartItems()
      }
    }).catch(error => console.log(error))
  };

  const handleDeleteWishListItem = (wishListItemID, productID) => {
    const body = {
      wishListItemID,
      productID
    }
    deleteWishListItem(body)
  }

  const handleMoveToShoppingCart = (wishListItemID, productID, quantity) => {

    let item = shoppingCartItems.find(item => item.productID === productID);

    if (item) {
      quantity += item.quantity
      console.log(quantity);
    }

    const body = {
      wishListItemID,
      productID,
      quantity
    }
    moveToShoppingCart(body)
  }

  const handleResize = () => {
    if (window.innerWidth < 1440) {
      setItemsPerPage(18)
    }
    if (itemsPerPage !== 20 && window.innerWidth > 1440) {
      setItemsPerPage(20)
    }
  };


  const totalPages = Math.ceil(products.length / itemsPerPage);

  const handleDivClick = (product) => {
    if ('showProductsHandler' in props) {
      props.showProductsHandler()
    }
    navigate('/product/' + product.name);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const productsToShow = products.slice(startIndex, endIndex);

  const handleScroll = () => {
    const targetHeight = window.innerHeight * 0.01;
    window.scrollTo({ top: targetHeight, behavior: 'smooth' });

  }

  const handleChange = (event, value) => {
    setCurrentPage(value);
    handleScroll()
    //navigate(location.pathname.replace(/\d+/g, '') + value)
  };

  useEffect(() => {
    //handleScroll()
  }, [products])

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    handleScroll()
    getWishListItems()
  }, [])

  return (
    <div className='container-main'>
      <div className='main-container'>
        {
          products.length > 0 ? <div className='products-card-main-container'>
          <div className='title-container' style={{ marginTop: '17vh', color: '#198754' }}>
            <h1>Wish List</h1>
          </div>
          <div className={`products-card-container`} style={{ marginTop: '5vh' }}>
            {
              productsToShow.length > 0 && productsToShow.map((product, index) =>
                <div className='product-card-container' key={product.name + '-' + product.brand}>
                  <WishListProduct
                    productKey={product.name}
                    product={product}
                    key={index}
                    index={index}
                    handleDivClick={handleDivClick}
                    onClick={() => { return showProductHandler() }}
                    loadingAddToCart={loadingAddToCart}
                    setLoadingAddToCart={setLoadingAddToCart}
                    handleDeleteWishListItem={handleDeleteWishListItem}
                    handleMoveToShoppingCart={handleMoveToShoppingCart}
                  />
                </div>
              )
            }
          </div>
          <div className='control-page-container' style={{ width: '100%', display: 'flex', justifyContent: 'center', padding: '25px 0', marginBottom: '35px', fontSize: '2rem', fontWeight: 900 }}>
            {products.length > 0 && <Pagination count={totalPages} page={currentPage} onChange={handleChange} color='primary' />}
          </div>
        </div>
        : !auth ? <UnauthorizedMsg />
        : (auth && emptyWishList) ? <EmptyWishListMsg />
        : <Loading />
        }
      </div>
    </div>
  )
}


class WishListProduct extends Component {
  render() {
    const { product, productKey, handleDivClick, loadingAddToCart, index, handleDeleteWishListItem, handleMoveToShoppingCart } = this.props
    const moneyFormat = {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    }

    return (
      <div className='product-container' key={productKey}>
        <div className='product-name'>
          <p onClick={() => handleDivClick(product)}>
            {product.name.toUpperCase()}
          </p>
        </div>
        <div className='img-container'>
          <div className='lazy-img-container' onClick={() => handleDivClick(product)}>
            <LazyLoadImage src={product.image} alt={product.name} className='product-img' />
          </div>
          <div className='product-certification'>
            {
              product.product_type === "vegan" ? <VeganCert id="miIcono" />
                : <FreshMilk id="miIcono" />
            }
            {
              product.allergens === "none" &&
              <img src={allergenFree} alt='Allergen free'
              />
            }
          </div>
        </div>
        <div className='brand-price-container' style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p style={{ marginLeft: '6%', color: '#495057', fontStyle: 'italic' }}>
            {product.brand.toUpperCase()}
          </p>
          <span style={{ marginRight: '6%', color: '#43b443' }}>
            <strong>
              {product.price.toLocaleString('en-US', moneyFormat)}
            </strong>
          </span>
        </div>
        <div className='btn-container' style={{ justifyContent: 'space-around' }}>
          <CartIconBtn id={product.name}
            loadingAddToCart={loadingAddToCart}
            index={index}
            onClick={() => {
              handleMoveToShoppingCart(product.wishListItemID, product.id, product.quantity)
            }}
          />
          <WishListBtn onClick={() => handleDeleteWishListItem(product.wishListItemID, product.id)} />
        </div>
      </div>
    )
  }
}
