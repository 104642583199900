import React, { Component } from 'react';
import '../styles/ProductFullView.css';
import BottomCar from './CustomizationBtnB';
import { ReactComponent as VeganCert } from '../vegan-cert.svg';
import { ReactComponent as FreshMilk } from '../fresh-milk.svg';
import allergenFree from '../allergen-free.png';
import NutritionTable from './NutritionTable';
import ProductSpec from './ProductSpec';

function FormattedText({ text }) {
  const parts = text.split(/(\*\*|\*|\*\*NOTE\*\* -|\!|\:)/);

  return (
    <div className='format-description'>
      {parts.map((part, index) => (
        <React.Fragment key={index}>
          {part === '**' || part === '**NOTE** -' || part === '*' ? <div><br/></div> :  part === '!' || part === ':' ? <span>{part}<div><br/></div></span> :part}
        </React.Fragment>
      ))}
    </div>
  );
}


export default class ProductFullView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showProductSpecs: true,
      showNutritionFacts: false,
      activeNutritionBtn: "",
      activeSpecBtn: "active",
      orientation: window.innerWidth > window.innerHeight ? 'landscape' : 'portrait',
      screenWidth: window.innerWidth
    };
  }
  
  toggleNutritionFacts = () => {
    this.setState({ showProductSpecs: false, activeSpecBtn: "" });
    this.setState({ showNutritionFacts: true });
    this.setState({ activeNutritionBtn: "active" });
  };
  toggleProductSpec = () => {
    this.setState({ showNutritionFacts: false, activeNutritionBtn: "" });
    this.setState({ showProductSpecs: true });
    this.setState({ activeSpecBtn: "active" });
  };
  handleResize = () => {
    this.setState({
      orientation: window.innerWidth > window.innerHeight ? 'landscape' : 'portrait',
      screenWidth: window.innerWidth
    });
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
  render() {
    const { product, addToCartProductsHandler } = this.props
    const moneyFormat = {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    }
    let nutritionData = {
      "Calories": product?.calories_kcal + " kcal",
      "Total fat": product?.total_fat_g + " g",
      "Saturated fat": product?.saturated_fat_g + " g",
      "Trans fat": product?.trans_fat_g + " g",
      "Cholesterol": product?.cholesterol_mg + " mg",
      "Sodium": product?.sodium_mg + " mg",
      "Potassium": product?.potassium_mg + " mg",
      "Carbs": product?.carbs_g + " g",
      "Sugars": product?.sugars_g + " g",
      "Fibre": product?.fibre_g + " g",
      "Protein": product?.protein_g + " g",
      "Calcium": product?.calcium_mg + " mg",
      "Iron": product?.iron_mg + " mg",
      "B12": product?.b12_mg + " mg",
      "Zinc": product?.zinc_mg + " mg"
    }

    const addToCartQty = (productID) => {
      const selectedQty = document.getElementById('add-car-qty')
      const quantity = parseInt(selectedQty.value)
      addToCartProductsHandler(productID, quantity)
    }

    return (
      <div className='main-product-container'>
        {
          this.state.orientation === 'portrait' && this.state.screenWidth < 560 || this.state.screenWidth < 860 ?
            <div className='portrait-product-container'>
              <div className='portrait-product-description-container'>
                <div className='product-name-full'>
                  <h2>
                    {product.name.toUpperCase()}
                  </h2>
                </div>
                <div className='product-measurement' >
                  <span>
                    {product.measurement}
                  </span>
                </div>
                <div className='portrait-product-img-container'>
                  <img src={product.image} alt={product.name} />
                </div>
                <div className='portrait-description-container'>
                  <div className='brand-certification'>
                    <div className='product-brand'>
                      <h3>
                        {product.brand.toUpperCase()}
                      </h3>
                    </div>
                  </div>
                  <div className='certification-container-full'>
                      {
                        product.product_type === "vegan" ? <VeganCert id="veganOrDairy" />
                          : <FreshMilk id="veganOrDairy" />
                      }
                      {
                        product.allergens === "none" &&
                        <img src={allergenFree}
                          alt='Allergen free' />
                      }
                    </div>
                  <div className='description'>
                    <p>
                      {product.description}
                    </p>
                  </div>
                  <div className='price'>
                    <span>
                      {product.price.toLocaleString('en-US', moneyFormat)}
                    </span>
                  </div>
                  <div className='portrait-add-to-car-btn-container'>
                    <select id='add-car-qty' defaultValue={1}>
                      <option value={1}>1</option>
                      <option value={2}>2</option>
                      <option value={3}>3</option>
                      <option value={4}>4</option>
                      <option value={5}>5</option>
                      <option value={6}>6</option>
                      <option value={7}>7</option>
                      <option value={8}>8</option>
                      <option value={9}>9</option>
                      <option value={10}>10</option>
                    </select>
                    <BottomCar id={product.name} onClick={() => {return addToCartQty(product.id)}} />
                  </div>
                </div>
              </div>
              <div className='product-specification-container'>
                <div className='btns-container' >
                  <button onClick={this.toggleProductSpec}
                    className='product-specification-btn' id={this.state.activeSpecBtn}>
                    Product Specification
                  </button>
                  <button onClick={this.toggleNutritionFacts}
                    className='nutritional-facts-btn' id={this.state.activeNutritionBtn}>
                    Nutritional Facts
                  </button>
                </div>
                {this.state.showNutritionFacts && (
                  <div className='nutritional-container'>
                    <h2>Nutritional Facts</h2>
                    <NutritionTable nutritionData={nutritionData} />
                  </div>
                )}
                {this.state.showProductSpecs && (
                  <div className='product-spec-container' >
                    <h2>Product specification</h2>
                    <ProductSpec product={product} />
                  </div>
                )
                }
              </div>
            </div>
            :
            <div className='landscape-product-container'>
              <div className='product-description-container'>
                <div className='product-img-container'>
                  <img src={product.image} alt={product.alt} />
                </div>
                <div className='description-container'>
                  <div className='product-name-full'>
                    <h2>
                      {product.name.toUpperCase()}
                    </h2>
                  </div>
                  <div className='product-measurement' >
                    <span>
                      {product.measurement}
                    </span>
                  </div>
                  <div className='brand-certification'>
                    <div className='product-brand'>
                      <h3>
                        {product.brand.toUpperCase()}
                      </h3>
                    </div>
                    <div className='certification-container-full'>
                      {
                        product.product_type === "vegan" ? <VeganCert id="veganOrDairy" />
                          : <FreshMilk id="veganOrDairy" />
                      }
                      {
                        product.allergens === "none" &&
                        <img src={allergenFree}
                          alt='Allergen free' />
                      }
                    </div>
                  </div>
                  <div className='description'>
                    <FormattedText text={product.description} />
                  </div>
                  <div className='price'>
                    <span>
                      {product.price.toLocaleString('en-US', moneyFormat)}
                    </span>
                  </div>
                  <div className='add-to-car-btn-container'>
                    <select id='add-car-qty' defaultValue={1}>
                      <option value={1}>1</option>
                      <option value={2}>2</option>
                      <option value={3}>3</option>
                      <option value={4}>4</option>
                      <option value={5}>5</option>
                      <option value={6}>6</option>
                      <option value={7}>7</option>
                      <option value={8}>8</option>
                      <option value={9}>9</option>
                      <option value={10}>10</option>
                    </select>
                    <BottomCar id={product.name} onClick={() => {return addToCartQty(product.id)}} />
                  </div>
                </div>
              </div>
              <div className='product-specification-container'>
                <div className='btns-container' >
                  <button onClick={this.toggleProductSpec}
                    className='product-specification-btn' id={this.state.activeSpecBtn}>
                    Product Specification
                  </button>
                  <button onClick={this.toggleNutritionFacts}
                    className='nutritional-facts-btn' id={this.state.activeNutritionBtn}>
                    Nutritional Facts
                  </button>
                </div>
                {this.state.showNutritionFacts && (
                  <div className='nutritional-container'>
                    <h2 style={{ marginBottom: "30px" }}>Nutritional Facts</h2>
                    <NutritionTable nutritionData={nutritionData} />
                  </div>
                )}
                {this.state.showProductSpecs && (
                  <div className='product-spec-container' >
                    <h2 style={{ marginBottom: "30px" }}>Product Specification</h2>
                    <ProductSpec product={product} />
                  </div>
                )
                }
              </div>
            </div>
        }
      </div>
    )
  }
}
