import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { useNavigate } from 'react-router-dom';
import { CaretRightFill, CaretLeftFill } from 'react-bootstrap-icons';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import '../styles/Carousel.css'

function DarkCarousel(props) {
  const { products, tags } = props

  const navigate = useNavigate();

  const handleDivClick = (productName) => {
    navigate('/product/' + productName);
  };

  return (
    <Carousel data-bs-theme="dark" interval={null} controls={false} className='carousel-container'>
          { products.length > 0 &&
            tags.map(tag => (
              <Carousel.Item key={tag} className='tag-container'>
                <h4 className='tag-name' >{`Discover our variety of ` + tag.toLowerCase()}</h4>
                <Carousel className='products-carousel' data-bs-theme="dark" indicators={false} interval={2000}
                  nextIcon={<CaretRightFill color="rgb(170, 221, 68)" size={36} />} prevIcon={<CaretLeftFill color="rgb(170, 221, 68)" size={32} />}
                >
                  {
                    products.filter(product => product.product_tags && product.product_tags.includes(tag)).map(filteredProduct => (
                      <Carousel.Item key={filteredProduct.name} className='second-carousel-item'>
                        <img
                          className="d-block carousel-image"
                          src={filteredProduct.image}
                          alt={filteredProduct.name}
                          onClick={() => { return handleDivClick(filteredProduct.name)}}
                        />
                        <div className='caption-container'>
                          <h5>{filteredProduct.name.toUpperCase()}</h5>
                        </div>
                      </Carousel.Item>
                    ))
                  }
                </Carousel>
              </Carousel.Item>
            ))
          }
        </Carousel>
  );
}

export default DarkCarousel;