import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactInputVerificationCode from "react-input-verification-code";
import '../styles/VerificationPage.css';

const VerificationPage = (props) => {
  const [value, setValue] = useState("");

  const [error, setError] = useState('');

  const [checking, setChecking] = useState(false)

  const navigate = useNavigate();

  const { fromRegister } = props

  const verifyEmail = (verificationCode) => {
    setChecking(true)
    fetch('https://ddannytech-https.ddns.net:4001/check-email-code', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      mode: 'cors',
      body: JSON.stringify({
        verificationCode
      })
    }).then(response => {
      if (response && response.ok) {
        localStorage.removeItem('token');
        response.text().then(data => {
          localStorage.setItem('token', data)
          setValue('');
          setError('');
          setChecking(false);
          return navigate('/');
        });
      }
      setValue('');
      setError('Oops! The code you entered isn’t valid. Please check your code and try again.');
      setChecking(false)
      return console.log('inconrrect code');
    }).catch(error => {
      console.error('Error:', error);
    });
  }

  const handleChange = (value) => {
    console.log(value);
    if (value.length > 5) {
      setError('The code cannot have more than 5 digits');
    } else if (!/^\d*$/.test(value)) {
      setError('The code can only contain digits');
    } else {
      setError('');
      if (value.length === 5) {
        console.log(value);
        setValue(value);
        verifyEmail(value)
        setValue('')
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (value.length < 5) {
      setError('Please enter an 5-digit code');
    } else {
      console.log(`Entered code: ${value}`);
    }
  };

  const checkAuth = useCallback(() => {
    fetch(`https://ddannytech-https.ddns.net:4001/check-auth`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      },
      mode: 'cors',
    })
    .then((response) => {
      if (response && response.ok) {
        console.log('Auth');
        return navigate('/');
      }
      if (response.status === 403 || response.statusText === 'Forbidden') {
        return navigate('/emailVerification')
      }
      if (response.status === 402) {
        return navigate('/VerificationLoginCode')
      }
      return null
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }, [navigate]);

  useEffect(() => {
    checkAuth()
  }, [])

  useEffect(() => {
    localStorage.removeItem('welcomeRegistration')
    localStorage.setItem('welcomeRegistration', true)
  }, [value])

  return (
    <div className="verification-page">
      <h1>Welcome to our vegan food store!</h1>
      <p>To authenticate your account, you will need to enter the verification code that was sent to your email. This code is a unique series of characters that ensures the security of your account by confirming your identity.</p>
      <form onSubmit={handleSubmit}>
        <div className="custom-styles">
          <ReactInputVerificationCode
            autoFocus
            placeholder=""
            onChange={setValue} 
            value={value}
            onCompleted={handleChange}
            length={5}
          />
        </div>
        {error && <p className="error" style={{ color: "red", marginTop: "20px", textAlign: 'center' }}>{error}</p>}
        <button type="submit" disabled={checking}>{checking ? 'Checking code' : "Verify Code"}</button>
      </form>
    </div>
  );
};

export default VerificationPage;

