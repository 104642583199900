import React from 'react';
import '../styles/NutritionTable.css'

const NutritionTable = ({ nutritionData }) => {
  const units = ['kcal', 'g', 'g']
  return (
    <table className="nutrition-table">
      <thead>
        <tr>
          <th>Nutrients</th>
          <th>Quantity</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(nutritionData).map(([key, value]) => (
          <tr key={key}>
            <td>{key}</td>
            <td>{value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default NutritionTable;
