import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { blue } from '@mui/material/colors';

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(blue[500]),
  backgroundColor: '#00BBF9',
  fontSize: 20,
  borderRadius: 18,
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: blue[900],
  },
}));

export default function CartIconBtn({ onClick, loadingAddToCart, index }) {
  return (
    <Stack spacing={2} direction="row">
      <ColorButton variant="contained" disableElevation onClick={onClick}>
        <i class="bi bi-cart-plus-fill"></i>
      </ColorButton>
    </Stack>
  );
}