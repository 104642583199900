import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';



const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#008000',
    },
    secondary: {
      main: '#6495ed'
    }
  },
});

export default function SignUp() {
  const [formErrors, setFormErrors] = useState({});
  const [signUpErrors, setSignUpErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [wait, setWait] = useState(false)

  const navigate = useNavigate();

  const signUp = (body) => {
    fetch('https://ddannytech-https.ddns.net:4001/register', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(body),
    })
      .then(response => {
        if (response && response.ok) {
          response.text().then(data => localStorage.setItem('token', data))
          return navigate('/emailVerification')
        }
        if (response && response.status === 403) {
          setWait(false)
          return response.json().then(data => setSignUpErrors({ message: data.message }))
        }
        if (response && response.status === 500) {
          setWait(false)
        }
      })
      .catch(error => {
        console.log(error);
        setTimeout(signUp, 10000);
      })
  }

  const validateField = (name, value) => {
    if (!value) return `${name.charAt(0).toUpperCase() + name.slice(1)} is required`;

    if (name === 'firstName' || name === 'lastName') {
      const nameRegex = /^[a-zA-Z]+$/;
      if (!nameRegex.test(value)) return `${name.charAt(0).toUpperCase() + name.slice(1)} can only contain letters`;
    }

    if (name === 'email') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value)) return 'Invalid email format';
    }

    if (name === 'username') {
      const usernameRegex = /^[a-zA-Z0-9]+$/;
      if (!usernameRegex.test(value)) return 'Username can only contain letters and alphanumeric characters';
    }

    if (name === 'password') {
      const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
      if (!passwordRegex.test(value)) return 'Password must contain minimum eight characters, at least one letter, one number and one special character';
    }

    return null;
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;
    setFormErrors({ ...formErrors, [name]: validateField(name, value) });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    for (let name of data.keys()) {
      const error = validateField(name, data.get(name));
      if (error) {
        setFormErrors({ ...formErrors, [name]: error });
        return;
      }
    }
    if (!termsAccepted) {
      return setSignUpErrors({ message: 'Registration requires your acceptance of the terms and conditions.' });
    }
    setWait(true)
    const body = {
      username: data.get('username'),
      email: data.get('email'),
      password: data.get('password'),
      firstName: data.get('firstName'),
      lastName: data.get('lastName')
    }
    signUp(body)
  };

  const checkAuth = useCallback(() => {
    fetch(`https://ddannytech-https.ddns.net:4001/check-auth`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      },
      mode: 'cors',
    })
      .then((response) => {
        if (response && response.ok) {
          console.log('Auth');
          return navigate('/');
        }
        if (response.status === 403 || response.statusText === 'Forbidden') {
          return navigate('/emailVerification')
        }
        if (response.status === 402) {
          return navigate('/VerificationLoginCode')
        }
        return null
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, [navigate]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    checkAuth()
  }, [])

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component='h1' variant='h4' sx={{ marginBottom: 2, textAlign: 'center', color: 'green' }}>
            Welcome to our Vegan Community!
          </Typography>
          <Typography textAlign='justify' marginBottom='20px'>
            Please provide your personal details below and embark on a journey towards a healthier, more sustainable lifestyle with us.
          </Typography>
          <Typography component="h1" variant="h5" color='darkgreen' fontWeight='bold'>
            Sign up
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={!!formErrors.firstName}
                  helperText={formErrors && formErrors.firstName}
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  onBlur={handleBlur}
                  onChange={handleBlur}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={!!formErrors.lastName}
                  helperText={formErrors && formErrors.lastName}
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                  onBlur={handleBlur}
                  onChange={handleBlur}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={!!formErrors.username}
                  helperText={formErrors && formErrors.username}
                  required
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  autoComplete="username"
                  onBlur={handleBlur}
                  onChange={handleBlur}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={!!formErrors.email}
                  helperText={formErrors && formErrors.email}
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onBlur={handleBlur}
                  onChange={handleBlur}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={!!formErrors.password}
                  helperText={formErrors && formErrors.password}
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  autoComplete="new-password"
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onBlur={handleBlur}
                  onChange={handleBlur}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" required checked={termsAccepted} onChange={(event) => {setTermsAccepted(event.target.checked); if (event.target.checked) {
                    return setSignUpErrors('')
                  }}} />}
                  label="By checking this box, you agree to our Terms of Service and acknowledge that you have read and understood our Privacy Policy."
                />
              </Grid>
            </Grid>
            {
              signUpErrors.message && <div style={{ color: 'red', marginBottom: '5px', paddingTop: '25px' }}>{signUpErrors.message}</div>
            }
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, backgroundColor: '#1976d2', fontWeight: 'bold' }}
              disabled={wait}
            >
              {wait ? 'Please wait...' : "Create Account"}
            </Button>
            <Grid container justifyContent="flex-end" style={{ paddingBottom: "35px" }}>
              <Grid item>
                <Link href="/signin" variant="body2" color="secondary">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}