import React, { useState, useEffect, useCallback } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Products from "./components/Products";
import './styles/App.css';
import ProductWithParams from './components/ProductWithParams'
import SignIn from './components/signIn';
import SignUp from './components/signUp';
import Footer from './components/Footer';
import About from './components/AboutUs';
import ShoppingCart from './components/shoppingCart';
import VerificationPage from './components/VerificationPage';
import VerificationLoginPage from './components/VerificationLoginPage';
import ShoppingCartFullView from './components/ShoppingCartFullView';
import Checkout from './components/Checkout';
import OrdersComponent from './components/viewOrders';
import SearchedProduct from './components/SearchedProducts';
import MyAccount from './components/MyAccount';
import UserInformation from './components/UserInformation';
import ShippingAddress from './components/ShippingAddress';
import WishList from './components/WishList';
import ForgotPassword from './components/ForgotPassword';
import ChangePassword from './components/ChangePassword';

const App = () => {
  const [state, setState] = useState({
    selected: 0,
    products: [],
    productsView: true,
    productFullView: false,
    productName: '',
    product: {},
    auth: true,
    showShoppingCart: false,
    emptyCart: false,
    emptyWishList: false,
    shoppingCartItems: [],
    tags: [],
    cartItemsNumb: 0,
    loadingAddToCart: '',
    wishList: []
  });

  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem('token');

  const getProducts = useCallback(() => {
    fetch('https://ddannytech-https.ddns.net:4001/main', {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${token}`,
      },
      mode: 'cors',
    })
      .then(response => {
        if (response && response.ok) {
          return response.json().then((products) => {
            setState(prevState => ({ ...prevState, products }));
          })
        }
        if ((response.status === 401 || response.statusText === 'Unauthorized') && window.location.pathname !== '/signup' && window.location.pathname !== '/emailVerification') {
          //localStorage.removeItem('token')
          return navigate('/signin');
        }
        if (response.status === 403 || response.statusText === 'Forbidden') {
          return navigate('/emailVerification')
        }
      })
      .catch(error => console.error('Error:', error));
  }, [navigate]);

  const addToCart = (productID, quantity, index) => {
    fetch('https://ddannytech-https.ddns.net:4001/shopping-cart', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      mode: 'cors',
      body: JSON.stringify({
        productID,
        quantity,
      })
    }).then(response => {
      if (response && response.ok) {
        return response.json().then((shoppingCartItems) => {
          setState(prevState => ({ ...prevState, shoppingCartItems }));
          if (state.products.length > 0) {
            setState((prevState) => ({ ...prevState, loadingAddToCart: '' }))
          }
        })
      }
      if ((response.status === 401 || response.statusText === 'Unauthorized') && window.location.pathname !== '/signup' && window.location.pathname !== '/emailVerification') {
        setState((prevState) => ({ ...prevState, loadingAddToCart: '' }))
        //localStorage.removeItem('token')
        return navigate('/signin');
      }
      if (response.status === 403 || response.statusText === 'Forbidden') {
        setState((prevState) => ({ ...prevState, loadingAddToCart: '' }))
        return navigate('/emailVerification')
      }
    })
      .catch(error => console.error('Error:', error));
  }

  const getCartItems = useCallback(() => {
    fetch('https://ddannytech-https.ddns.net:4001/shopping-cart', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${token}`
      },
      mode: 'cors',
    }).then(response => {
      if (response && response.ok) {
        return response.json().then((shoppingCartItems) => {
          setState(prevState => ({ ...prevState, shoppingCartItems, auth: true }));
        })
      }
      if ((response.status === 401 || response.statusText === 'Unauthorized') && window.location.pathname !== '/signup' && window.location.pathname !== '/emailVerification') {
        setState((prevState) => ({ ...prevState, auth: false }))
        //return localStorage.removeItem('token')
        //return navigate('/signin');
      }
      if (response.status === 403 || response.statusText === 'Forbidden') {
        setState((prevState) => ({ ...prevState, auth: false }))
        return navigate('/emailVerification')
      }
    })
      .catch(error => console.error('Error:', error));
  }, [token, navigate]);

  const deleteCartItem = useCallback((cartID, productID) => {
    fetch('https://ddannytech-https.ddns.net:4001/shopping-cart', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${token}`
      },
      mode: 'cors',
      body: JSON.stringify({
        cartID,
        productID
      })
    }).then(response => {
      if (response && response.status === 204) {
        console.log("Delete");
        setState(prevState => ({
          ...prevState,
          shoppingCartItems: prevState.shoppingCartItems.filter(item => item.productID !== productID)
        }));
      }
    })
  }, [token]);

  const addToWishList = useCallback((body) => {
    fetch('https://ddannytech-https.ddns.net:4001/wish-list', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${token}`
      },
      mode: 'cors',
      body: JSON.stringify(body)
    }).then(response => {
      if (response && response.ok) {
        getWishListItems()
      }
    })
  }, [token])

  const getWishListItems = useCallback(() => {
    fetch('https://ddannytech-https.ddns.net:4001/wish-list', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${token}`
      },
      mode: 'cors',
    }).then(response => {
      if (response && response.ok) {
        response.json().then(data => {
          setState((prevState) => ({ ...prevState, wishList: data }))
        })
      }
      if (response && !response.ok) {
        setState((prevState) => ({ ...prevState, auth: false }))
      }
    })
  })

  const showProductsHandler = () => {
    setState(prevState => ({ ...prevState, productFullView: false, productsView: true }));
  }

  const showProductHandler = useCallback(() => {
    if (!state.productsView) {
      setState(prevState => ({ ...prevState, productsView: true }));
    }
  }, [state.productsView, state.searchProductsView]);

  const toggleShoppingCart = () => {
    setState(prevState => {
      const showShoppingCart = !prevState.showShoppingCart;
      return { ...prevState, showShoppingCart };
    });
  };

  const setShoppingCartItems = (newItems) => {
    setState(prevState => ({ ...prevState, shoppingCartItems: newItems }));
  };

  const addToCartProductsHandler = (productID, quantity) => {

    if (state.shoppingCartItems.find(item => item.productID === productID)) {
      let actualQty = state.shoppingCartItems.find(item => item.productID === productID).quantity
      if (actualQty === 25) {
        return
      }
      if ((quantity + actualQty) > 25) {
        quantity = 25
      }
      else {
        quantity = quantity + actualQty
      }
    }
    addToCart(productID, quantity)
  }

  const setCartItemQty = () => {
    const totalQuantity = state.shoppingCartItems.reduce((total, item) => total + item.quantity, 0);
    return setState(prevState => ({
      ...prevState,
      cartItemsNumb: totalQuantity
    }));
  };

  const HandlerDeleteCartItem = (cartID, productID) => {
    deleteCartItem(cartID, productID)
  }

  const setLoadingAddToCart = (index) => {
    console.log(index);
    setState(prevState => ({ ...prevState, loadingAddToCart: index }));
  }

  const handlerAddToWishList = (body) => {
    addToWishList(body)
  }

  const handleOnWishList = () => {
    if (state.wishList.length > 0) {
      let wishListSet = new Set(state.wishList.map(item => item.productID))
      let productsW = state.products.map(product => ({
        ...product,
        onWishList: wishListSet.has(product.id)
      }))
      setState((prevState) => ({ ...prevState, products: productsW }))
    } else {
      let productW = state.products.map(product => ({
        ...product,
        onWishList: false
      }))
      setState((prevState) => ({ ...prevState, products: productW}))
    }
  }

  const HandleEmptyCart = useCallback(() => {
    if (state.shoppingCartItems.length === 0) {
      setState((prevState) => ({ ...prevState, emptyCart: true }))
    }
    if (state.shoppingCartItems.length > 0) {
      setState((prevState) => ({ ...prevState, emptyCart: false }))
    }
  })

  const handleEmptyWishList = useCallback(() => {
    if (state.wishList.length === 0) {
      setState((prevState) => ({ ...prevState, emptyWishList: true }))
    }
    else {
      setState((prevState) => ({ ...prevState, emptyWishList: false }))
    }
  })

  let dairy = [];
  let vegan = [];

  useEffect(() => {
    if (state.products.length === 0) {
      getProducts();
    }
  }, [deleteCartItem]);

  useEffect(() => {
    getCartItems();
    getWishListItems()
  }, [token])

  useEffect(() => {
    setCartItemQty()
  }, [state.cartItemsNumb, state.shoppingCartItems])

  useEffect(() => {
    showProductHandler()
    getCartItems()
  }, [])

  useEffect(() => {
    HandleEmptyCart()
  }, [state.shoppingCartItems])

  useEffect(() => {
    if (state.products.length > 0) {
      handleOnWishList()
    }
    handleEmptyWishList()
  },[state.wishList])


  return (
    <div className="App">
      <div className='main-container'>
        <Header
          selected={state.selected}
          showProductsHandler={showProductsHandler}
          toggleShoppingCart={toggleShoppingCart}
          showShoppingCart={state.showShoppingCart}
          cartItemsQty={state.cartItemsNumb}
          auth={state.auth}
        />
        {state.showShoppingCart &&
          <ShoppingCart
            show={state.showShoppingCart}
            shoppingCartItems={state.shoppingCartItems}
            setShoppingCartItems={setShoppingCartItems}
            HandlerDeleteCartItem={HandlerDeleteCartItem}
            toggleShoppingCart={toggleShoppingCart}
            auth={state.auth}
            addToCart={addToCart}
            emptyCart={state.emptyCart}
          />}

        <Routes>
          <Route path='/' element={
            <Products
              products={state.products}
              addToCartProductsHandler={addToCartProductsHandler}
              showProductHandler={showProductHandler}
              loadingAddToCart={state.loadingAddToCart}
              setLoadingAddToCart={setLoadingAddToCart}
              handlerAddToWishList={handlerAddToWishList}
            />
          } />
          {
            state.products.map((item) => {
              return item.product_type === "vegan" ? vegan.push(item) : dairy.push(item);
            })
          }
          <Route path='/Vegan' element={
            <div className='main-container'>
              <Products
                products={vegan}
                addToCartProductsHandler={addToCartProductsHandler}
                showProductHandler={showProductHandler}
                loadingAddToCart={state.loadingAddToCart}
                setLoadingAddToCart={setLoadingAddToCart}
                handlerAddToWishList={handlerAddToWishList}
              />
            </div>
          } />
          <Route path='/Dairy' element={
            <div className='main-container'>
              <Products
                addToCartProductsHandler={addToCartProductsHandler}
                products={dairy}
                showProductHandler={showProductHandler}
                loadingAddToCart={state.loadingAddToCart}
                setLoadingAddToCart={setLoadingAddToCart}
                handlerAddToWishList={handlerAddToWishList}
              />
            </div>}
          />
          <Route path='/product/:productName/*'
            element={<ProductWithParams addToCartProductsHandler={addToCartProductsHandler} />}
          />

          <Route path='/products/:id/*'
            element={<SearchedProduct 
              addToCartProductsHandler={addToCartProductsHandler} 
              loadingAddToCart={state.loadingAddToCart}
              setLoadingAddToCart={setLoadingAddToCart}
              handlerAddToWishList={handlerAddToWishList}
              wishList={state.wishList}
              />}
          />

          <Route path='/signin'
            element={
              <SignIn />
            }
          />

          <Route path='/signup'
            element={
              <SignUp />
            }
          />

          <Route path='/aboutus'
            element={
              <About />
            }
          />

          <Route path='/emailVerification'
            element={
              <VerificationPage fromRegister={state.fromRegister} />
            }
          />

          <Route path='/VerificationLoginCode'
            element={
              <VerificationLoginPage fromRegister={state.fromRegister} />
            }
          />

          <Route path='/shopping-cart-view'
            element={
              <ShoppingCartFullView
                shoppingCartItems={state.shoppingCartItems}
                setShoppingCartItems={setShoppingCartItems}
                HandlerDeleteCartItem={HandlerDeleteCartItem}
                addToCartProductsHandler={addToCartProductsHandler}
                addToCart={addToCart}
                getCartItems={getCartItems}
                auth={state.auth}
                emptyCart={state.emptyCart}
              />
            }
          />
          <Route path='/checkout'
            element={
              <Checkout shoppingCartItems={state.shoppingCartItems} getCartItems={getCartItems} auth={state.auth} emptyCart={state.emptyCart} HandleEmptyCart={HandleEmptyCart} />
            }
          />
          <Route path='/view-orders'
            element={
              <OrdersComponent getCartItems={getCartItems} auth={state.auth} />
            }
          />
          <Route path='/my-account'
            element={
              <MyAccount auth={state.auth} />
            }
          />
          <Route path='/my-account/user-information'
            element={
              <UserInformation auth={state.auth} />
            }
          />
          <Route path='/my-account/shipping-address'
            element={
              <ShippingAddress auth={state.auth} />
            }
          />
          <Route path='/wish-list'
            element={
              <WishList
                products={state.wishList}
                addToCartProductsHandler={addToCartProductsHandler}
                showProductHandler={showProductHandler}
                loadingAddToCart={state.loadingAddToCart}
                setLoadingAddToCart={setLoadingAddToCart}
                handlerAddToWishList={handlerAddToWishList}
                getWishListItems={getWishListItems}
                getCartItems={getCartItems}
                shoppingCartItems={state.shoppingCartItems}
                auth={state.auth}
                emptyWishList={state.emptyWishList}
              />
            }
          />
          <Route path='/reset-password'
            element={
              <ForgotPassword />
            }
          />
          <Route path='/:id/change-password'
            element={
              <ChangePassword />
            }
          />
        </Routes>
        <Footer />
      </div>
    </div>
  );
}

export default App;
