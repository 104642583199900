import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom'
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import OutlinedInput from '@mui/material/OutlinedInput';
import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';

const FormGrid = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export default function ShippingAddress() {

  const [values, setValues] = useState(JSON.parse(localStorage.getItem('formValues')) || {});

  const token = localStorage.getItem('token')

  const navigate = useNavigate()

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
    //saveAddress(values)
  };

  const handleClick = () => {
    saveAddress()
  }

  const saveAddress = () => {
    fetch('https://ddannytech-https.ddns.net:4001/shipping-address', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(values),
      mode: 'cors',
    }).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      console.log(response);
      localStorage.removeItem('formValues')
      localStorage.setItem('formValues', JSON.stringify(values));
    }).catch(error => {
      console.log(error);
    })
  }

  const getAddress = () => {
    console.log(values);
    fetch('https://ddannytech-https.ddns.net:4001/shipping-address', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${token}`
      },
      mode: 'cors',
    }).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      response.json().then(data => {
        localStorage.removeItem('formValues')
        localStorage.setItem('formValues', JSON.stringify(data));
      })
    }).catch(error => {
      console.log(error);
      //setTimeout(fetchOrder, 6000);
    })
  }

  const checkAuth = useCallback(() => {
    fetch(`https://ddannytech-https.ddns.net:4001/check-auth`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      },
      mode: 'cors',
    })
      .then((response) => {
        if (response.status === 401) {
          return navigate('/signin')
        }
        if (response.status === 403 || response.statusText === 'Forbidden') {
          return navigate('/emailVerification')
        }
        if (response.status === 402) {
          return navigate('/VerificationLoginCode')
        }
        return null
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, [navigate]);

  useEffect(() => {
    checkAuth()
  }, [])

  useEffect(() => {
    localStorage.setItem('formValues', JSON.stringify(values));
  }, [values]);

  useEffect(() => {
    const storedValues = JSON.parse(localStorage.getItem('formValues'));
    if (storedValues) {
      const valuesString = Object.entries(storedValues)
        .map(([key, value]) => `'${key}':'${value}'`)
        .join(';');
      console.log(valuesString);
    }
  }, []);

  useEffect(() => {
    getAddress()
  }, [])

  return (
    <div className='shipping-address-container' style={{ width: '100%', minHeight: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20vh', marginBottom: '10vh' }}>
      <Typography style={{ width: '100%' }}>
        <Link href="/my-account" variant="body2" style={{ color: "#000", textDecoration: 'none', marginLeft: "30px" }}>
          <i class="bi bi-arrow-left-short" style={{ fontSize: '1.2rem' }}></i> <span style={{ fontSize: '1.2rem' }}>Return</span>
        </Link>
      </Typography>
      <div style={{ marginBottom: '8vh', color: '#008000' }}>
        <h2>Shipping Address</h2>
      </div>
      <Grid container spacing={3} style={{ width: '80%' }}>
        <FormGrid item xs={12} md={6}>
          <FormLabel htmlFor="firstName" required>
            First name
          </FormLabel>
          <OutlinedInput
            id="firstName"
            name="firstName"
            type="name"
            placeholder="John"
            autoComplete="first name"
            required
            onChange={handleChange}
            value={values['firstName'] || ''}
          />
        </FormGrid>

        <FormGrid item xs={12} md={6}>
          <FormLabel htmlFor="lastName" required>
            Last name
          </FormLabel>
          <OutlinedInput
            id="last-name"
            name="lastName"
            type="lastName"
            placeholder="Last Name"
            autoComplete="last name"
            required
            onChange={handleChange}
            value={values['lastName'] || ''}
          />
        </FormGrid>

        <FormGrid item xs={12}>
          <FormLabel htmlFor="address1" required>
            Address line 1
          </FormLabel>
          <OutlinedInput
            id="address1"
            name="address1"
            type="address1"
            placeholder="Street name and number"
            autoComplete="shipping address-line1"
            required
            onChange={handleChange}
            value={values['address1'] || ''}
          />
        </FormGrid>

        <FormGrid item xs={12}>
          <FormLabel htmlFor="address2">Address line 2</FormLabel>
          <OutlinedInput
            id="address2"
            name="address2"
            type="address2"
            placeholder="Apartment, suite, unit, etc. (optional)"
            autoComplete="shipping address-line2"
            required
            onChange={handleChange}
            value={values['address2'] || ''}
          />
        </FormGrid>

        <FormGrid item xs={6}>
          <FormLabel htmlFor="city" required>
            City
          </FormLabel>
          <OutlinedInput
            id="city"
            name="city"
            type="city"
            placeholder="New York"
            autoComplete="City"
            required
            onChange={handleChange}
            value={values['city'] || ''}
          />
        </FormGrid>

        <FormGrid item xs={6}>
          <FormLabel htmlFor="state" required>
            State
          </FormLabel>
          <OutlinedInput
            id="state"
            name="state"
            type="state"
            placeholder="NY"
            autoComplete="State"
            required
            onChange={handleChange}
            value={values['state'] || ''}
          />
        </FormGrid>

        <FormGrid item xs={6}>
          <FormLabel htmlFor="zip" required>
            Zip / Postal code
          </FormLabel>
          <OutlinedInput
            id="zip"
            name="zip"
            type="zip"
            placeholder="12345"
            autoComplete="shipping postal-code"
            required
            onChange={handleChange}
            value={values['zip'] || ''}
          />
        </FormGrid>

        <FormGrid item xs={6}>
          <FormLabel htmlFor="country" required>
            Country
          </FormLabel>
          <OutlinedInput
            id="country"
            name="country"
            type="country"
            placeholder="United States"
            autoComplete="shipping country"
            required
            onChange={handleChange}
            value={values['country'] || ''}
          />
        </FormGrid>
      </Grid>
      <Button
        type="submit"
        variant="contained"
        sx={{ mt: 3, mb: 2, backgroundColor: '#1976d2', fontWeight: 'bold', marginTop: "50px" }}
        onClick={() => handleClick()}
      >
        Save Changes
      </Button>
    </div>
  );
}