import React, { useState, useEffect, useCallback } from 'react';
import Pagination from '@mui/material/Pagination';
import { useNavigate, useLocation } from 'react-router-dom';
import '../styles/Products.css'
import Product from "./Product";
import WelcomePopupLogin from './WelcomePopupLogin';
import WelcomePopupRegister from './WelcomePopupRegister';
import DarkCarousel from './Carousel';

export default function Products(props) {
  const { products, addToCartProductsHandler, searchProductsView, showProductHandler, loadingAddToCart, setLoadingAddToCart, handlerAddToWishList } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const [currentPage, setCurrentPage] = useState(1);
  const [tags, setTags] = useState([])
  const [itemsPerPage, setItemsPerPage] = useState(20);

  const handleResize = () => {
    if (window.innerWidth < 1440) {
      setItemsPerPage(18)
    }
    if(itemsPerPage !== 20 && window.innerWidth > 1440) {
      setItemsPerPage(20)
    }
  };


  const totalPages = Math.ceil(products.length / itemsPerPage);

  const handleDivClick = (product) => {
    if ('showProductsHandler' in props) {
      props.showProductsHandler()
    }
    navigate('/product/' + product.name);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const productsToShow = products.slice(startIndex, endIndex);

  const handleScroll = () => {
    const targetHeight = window.innerHeight * 0.01;
    window.scrollTo({top: targetHeight, behavior: 'smooth'});
  }

  const handleChange = (event, value) => {
    setCurrentPage(value);
    handleScroll()
    //navigate(location.pathname.replace(/\d+/g, '') + value)
  };

  const getTags = useCallback(() => {
    const tagsSet = new Set();
    products.forEach(product => {
      if (typeof product.product_tags === 'string' && product.product_tags.trim()) {
        const tagsArray = product.product_tags.split(',').map(tag => tag.trim());
        tagsArray.forEach(tag => tagsSet.add(tag));
      }
    });
    setTags(Array.from(tagsSet))
  }, [products]);

 useEffect(() => {
    //handleScroll()
    if (products.length > 0) {
      getTags()
    }
  }, [products])

  useEffect(() => {
    if (tags.length < 1) {
      getTags()
    }
  }, [])

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    handleScroll()
  }, [])

  useEffect(() => {
    handleScroll()
  }, [currentPage])

  useEffect(() => {
    if (tags.length === 0) {
      handleScroll()
    }
  }, [products])

  return (
    <div className='container-main'>
      <div className='main-container'>
        {localStorage.getItem('welcomeLogin') == 'true' && <WelcomePopupLogin />}
        {localStorage.getItem('welcomeRegistration') == 'true' && <WelcomePopupRegister />}

        {(tags.length > 0 && currentPage === 1 && !location.pathname.includes('/products/')) && <DarkCarousel products={products} tags={tags} />}
        <div className='products-card-main-container' style={{ minHeight: '100vh' }}>
          <div className={`products-card-container ${searchProductsView ? 'search-products-container' : ''}`}>
            {
              productsToShow.length > 0 && productsToShow.map((product, index) =>
                <div className='product-card-container' key={product.name + '-' + product.brand}>
                  <Product
                    addToCartProductsHandler={addToCartProductsHandler}
                    productKey={product.name}
                    product={product}
                    key={index}
                    index={index}
                    handleDivClick={handleDivClick}
                    onClick={() => { return showProductHandler() }}
                    loadingAddToCart={loadingAddToCart}
                    setLoadingAddToCart={setLoadingAddToCart}
                    handlerAddToWishList={handlerAddToWishList}
                  />
                </div>
              )
            }
          </div>
          <div className='control-page-container' style={{ width: '100%', display: 'flex', justifyContent: 'center', padding: '25px 0', marginBottom: '35px', fontSize: '2rem', fontWeight: 900 }}>
            {products.length > 0 && <Pagination count={totalPages} page={currentPage} onChange={handleChange} color='primary' />}
          </div>
        </div>
      </div>
    </div>
  )
}
