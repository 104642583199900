import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';

function Footer() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const location = useLocation()

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 708);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (location.pathname === '/checkout' || location.pathname === '/#/checkout') {
    return null;
  }

  return (
    <footer style={{ backgroundColor: '#4CAF50', color: 'white', padding: '20px 0', textAlign: isMobile ? 'center' : 'left', marginBottom: isMobile ? '6.4vh' : '0' }}>
      <div style={{ display: isMobile ? 'block' : 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
        <div>
          <h3>Vegan Market</h3>
          <p>Healthy and sustainable food at your fingertips.</p>
        </div>
        {!isMobile && (
          <div>
            <h4>Resources</h4>
            <ul style={{ listStyle: 'none', display: 'flex', flexDirection: 'column', alignItems: "flex-start",paddingLeft: "0px" }}>
              <li><a href="/#/aboutus" style={{ color: 'white' }}>About Us</a></li>
              <li><a href="/#/contact" style={{ color: 'white' }}>Contact</a></li>
              <li><a href="/#/privacy" style={{ color: 'white' }}>Privacy Policy</a></li>
            </ul>
          </div>
        )}
        <div>
          <h4>Follow Us</h4>
          <a href="https://www.facebook.com" style={{ color: 'white', marginRight: '10px' }}><FaFacebook style={{ width: '26px', height: '26px' }} /></a>
          <a href="https://www.twitter.com" style={{ color: 'white', marginRight: '10px' }}><FaTwitter style={{ width: '26px', height: '26px' }} /></a>
          <a href="https://www.instagram.com" style={{ color: 'white' }}><FaInstagram style={{ width: '26px', height: '26px' }} /></a>
        </div>
      </div>
      <div style={{ textAlign: 'center', marginTop: '10px' }}>
        <p>© {new Date().getFullYear()} Vegan Market.</p>
      </div>
    </footer>
  );
}

export default Footer;


