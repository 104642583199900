import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCardText,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBInput,
  MDBRow,
  MDBTypography,
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import '../styles/ShoppingCartFullView.css';
import Loading from "./Loading";

const UnauthorizedMsg = () => {
  const navigate = useNavigate()
  return (
    <div className="Unauthorized-msg-container" style={{ height: '100vh', width: '100%', display: 'flex', justifyContent: "center", alignItems: "center" }}>
      <div style={{ backgroundColor: "#f9f9f9", padding: "20px", border: "1px solid #ddd", borderRadius: "12px", textAlign: "center" }}>
      <p style={{ fontSize: "18px", color: "#333" }}>You must be logged in to view your shopping cart.</p>
      <p style={{ fontSize: "16px", color: "#666" }}>Please sign in to access your cart:</p>
      <button onClick={() => navigate('/signin')} style={{ backgroundColor: "#00BBF9", borderRadius: "12px", marginTop: "15px", marginBottom: "20px", width: '80%', color: "#fff", border: "none", padding: "10px 20px", fontSize: "18px", fontWeight: "bold", cursor: "pointer", marginRight: "10px" }}>Sign In</button>
      <p><a href='/signup'>Don't have an account? Sign Up</a></p>
    </div>
    </div>
  )
}

const EmptyCartMessage = () => {
  const [screenHorientation, setScreenHorientation] = useState(window.innerWidth > window.innerHeight ? 'landscape' : 'portrait');

  const handleHorientation = () => {
    setScreenHorientation(window.innerWidth > window.innerHeight ? 'landscape' : 'portrait');
  }

  const handleScroll = () => {
    const targetHeight = window.innerHeight * 0.1;
    window.scrollTo({ top: targetHeight, behavior: 'smooth' });
  }

  useEffect(() => {
    handleScroll()
    handleHorientation()
  }, [])

  useEffect(() => {
    window.addEventListener('resize', handleHorientation);

    return () => {
      window.removeEventListener('resize', handleHorientation);
    }
  }, [])

  const portrait = {
    width: "80%", border: '2px solid #ccc', padding: '20px', backgroundColor: '#fff', textAlign: 'center'
  }

  const landscape = {
    width: "50%", border: '2px solid #ccc', padding: '20px', backgroundColor: '#fff', textAlign: 'center'
  }

  return (
    <div style={{ width: "100%", minHeight: "100vh", display: "flex", alignItems: "center", justifyContent: "center", marginTop: "14vh" }}>
      <div style={screenHorientation === 'landscape' ? landscape : portrait}>
        <h2 style={{ paddingTop: '30px', marginBottom: "30px" }}>
          SHOPPING CART
        </h2>
        <img style={{ width: "30%" }} src='https://i.ibb.co/3Nf5XGG/shopping-cart-PNG50.png'></img>
        <h1 style={{ marginBottom: "50px" }}>
          Your Shopping Cart Is Currently Empty.
        </h1>
        <p style={{ fontSize: '18px', textAlign: 'justify' }}>Dear custumer, whether you're hunting for a delicious beyond meat, a sweet vegan snack, or a whimsical gift, <strong><a href='/'>let our home page be your compass</a></strong>.</p>
        <p style={{ fontSize: '18px' }}>Explore, click, and add to cart.</p>
      </div>
    </div>
  );
};

export default function ShoppingCartFullView(props) {

  const { shoppingCartItems, setShoppingCartItems, addToCart, HandlerDeleteCartItem, getCartItems, emptyCart, auth } = props;

  const [totalItems, setTotalItems] = useState(0)
  const [subtotalPrice, setSubtotalPrice] = useState(0)
  const [totalPrice, setTotalPrice] = useState(0)

  const navigate = useNavigate()

  const token = localStorage.getItem('token')

  const moveToWishList = (body) => {
    fetch('https://ddannytech-https.ddns.net:4001/move-to-wish-list', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
      },
      mode: 'cors',
      body: JSON.stringify(body)
    }).then(response => {
      if (response && response.ok) {
        getCartItems()
      }
    }).catch(error => console.log(error))
  };

  const handleMoveToWishList = (productID, quantity, cartID) => {
    const body = {
      productID,
      quantity,
      cartID
    }
    moveToWishList(body)
  }

  const incrementQuantity = (productID) => {
    let quantityChanged = false;
    const newItems = shoppingCartItems.map(item => {
      if (item.productID === productID && item.quantity < 25) {
        quantityChanged = true;
        return { ...item, quantity: item.quantity + 1 };
      } else {
        return item;
      }
    });
    if (quantityChanged) {
      setShoppingCartItems(newItems);
      addToCart(productID, newItems.find(item => item.productID === productID).quantity);
    }
  };

  const decrementQuantity = (productID) => {
    let quantityChanged = false;
    const newItems = shoppingCartItems.map(item => {
      if (item.productID === productID && item.quantity > 1) {
        quantityChanged = true;
        return { ...item, quantity: item.quantity - 1 };
      } else {
        return item;
      }
    });
    if (quantityChanged) {
      setShoppingCartItems(newItems);
      const productIndex = newItems.findIndex(item => item.productID === productID);
      addToCart(productID, newItems.find(item => item.productID === productID).quantity, productIndex);
    }
  };

  const updateQuantity = (productID, newQuantity, index) => {
    newQuantity = Number(newQuantity);
    let errorMsg = document.getElementById(`error-msg-${index}`)

    if (newQuantity === 0) {
      newQuantity = 1
    }
    if (newQuantity > 25) {
      errorMsg.innerText = 'Please note that you cannot add more than 25 units of the same product at once.'
      newQuantity = 25
    }
    if (errorMsg && errorMsg.innerText) {
      setTimeout(function () {
        errorMsg.innerText = '';
      }, 5000);
    }

    const newItems = shoppingCartItems.map(item => {
      if (item.productID === productID) {
        return { ...item, quantity: newQuantity };
      } else {
        return item;
      }
    });
    setShoppingCartItems(newItems);
  };

  const updateCartItem = (productID, quantity) => {
    addToCart(productID, quantity)
  }

  const setItems = () => {
    const total = shoppingCartItems.reduce((acc, item) => acc + item.quantity, 0);
    setTotalItems(total);
  }

  const setSubtotalPriceHandler = () => {
    let price = 0
    for (let i = 0; i < shoppingCartItems.length; i++) {
      price = (shoppingCartItems[i].price * shoppingCartItems[i].quantity) + price
    }
    setSubtotalPrice(price)
  }

  const setTotalPriceHandler = () => {
    if (shoppingCartItems && shoppingCartItems.length > 0 && !emptyCart) {
      let shipping = parseFloat(document.getElementById('shipping-opt').value)
      setTotalPrice(subtotalPrice + shipping);
    }
  }

  const handleToCheckOut = () => {
    navigate('/checkout')
  }

  const handleScroll = () => {
    const targetHeight = window.innerHeight * 0.05;
    window.scrollTo({ top: targetHeight, behavior: 'smooth' });
  }

  useEffect(() => {
    handleScroll()
    getCartItems()
  }, [])

  useEffect(() => {
    setItems()
    setSubtotalPriceHandler()
    setTotalPriceHandler()
  }, [totalItems, setShoppingCartItems]);

  return (
    <div className='shopping-cart-full-view'>
      <div className='shopping-cart-main-container' style={{ display: 'flex', justifyContent: 'center', width: "100%" }}>
        <section className="h-100 h-custom" style={{ backgroundColor: "#f0f8ff", width: '100%', minHeight: '100vh' }}>
          {
            shoppingCartItems.length > 0 && !emptyCart ?
              <MDBContainer className="py-5 h-100" style={{ marginTop: "15vh" }}>
                <MDBRow className="justify-content-center align-items-center h-100">
                  <MDBCol size="12">
                    <MDBCard className="card-registration card-registration-2" style={{ borderRadius: "15px" }}>
                      <MDBCardBody className="p-0">
                        <MDBRow className="g-0">
                          <MDBCol lg="8">
                            <div className="p-5">
                              <div className="d-flex justify-content-between align-items-center mb-5">
                                <MDBTypography tag="h1" className="fw-bold mb-0 text-black">
                                  Shopping Cart
                                </MDBTypography>
                                <MDBTypography className="mb-0 text-muted">
                                  {totalItems} cart items
                                </MDBTypography>
                              </div>

                              <hr className="my-4" />

                              {shoppingCartItems.length > 0 && (
                                shoppingCartItems.map((item, index) => (
                                  <MDBRow className="mb-4 d-flex justify-content-between align-items-center" id="mobile-for">
                                    <MDBCol md="2" lg="2" xl="2" style={{ display: 'flex', justifyContent: 'center' }}>
                                      <MDBCardImage style={{ maxHeight: '240px' }}
                                        src={item.image}
                                        fluid className="rounded-3" alt="Cotton T-shirt" />
                                    </MDBCol>
                                    <MDBCol md="3" lg="3" xl="3">
                                      <MDBTypography tag="h6" className="text-muted">
                                        {item.brand.toUpperCase()}
                                      </MDBTypography>
                                      <MDBTypography tag="h6" className="text-black mb-0">
                                        {item.name.toUpperCase()}
                                      </MDBTypography>
                                    </MDBCol>
                                    <MDBCol md="3" lg="3" xl="3" className="d-flex align-items-center" id="mobile-for-quantity">
                                      <MDBBtn color="link" className="px-2"
                                        onClick={() => decrementQuantity(item.productID)}
                                      >
                                        <i className="bi bi-dash-circle"
                                          style={{ color: 'red', fontSize: '1.5rem', cursor: 'pointer' }}
                                        />
                                      </MDBBtn>

                                      <MDBInput type="number" min="0" value={item.quantity} onChange={(e) => updateQuantity(item.productID, e.target.value, index)} onBlur={(e) => updateCartItem(item.productID, e.target.value)} size="sm" />

                                      <MDBBtn color="link" className="px-2"
                                        onClick={() => incrementQuantity(item.productID)}
                                      >
                                        <i className="bi bi-plus-circle"
                                          style={{ color: 'green', fontSize: '1.5rem', cursor: 'pointer' }}
                                        />
                                      </MDBBtn>
                                    </MDBCol>
                                    <MDBCol md="3" lg="2" xl="2" className="text-end total-price">
                                      <MDBTypography tag="h6" className="mb-0">
                                        ${(item.quantity * item.price).toFixed(2)}
                                      </MDBTypography>
                                    </MDBCol>
                                    <MDBCol md="1" lg="1" xl="1" className="text-end trash">
                                      <i className="bi bi-trash-fill" style={{ color: 'red', fontSize: '2rem', cursor: 'pointer' }}
                                        onClick={() => { return HandlerDeleteCartItem(item.cartID, item.productID) }}
                                      />
                                    </MDBCol>
                                    <div id={'error-msg-' + index} style={{ marginRight: "15px", position: "relative", top: "10px", color: "red", textAlign: "center", fontSize: "14px" }}></div>
                                    <div className="my-3 text-center" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                                      <div className="move-to-wish-list-container" onClick={() => handleMoveToWishList(item.productID, item.quantity, item.cartID)}>
                                        <i class="bi bi-bag-heart"></i>
                                      </div>
                                    </div>
                                    <hr className="my-2" />
                                  </MDBRow>
                                )))}

                              <div className="pt-5">
                                <MDBTypography tag="h6" className="mb-0">
                                  <MDBCardText tag="a" href="/" className="text-body">
                                    <i class="bi bi-arrow-left"></i>
                                    Back to shop
                                  </MDBCardText>
                                </MDBTypography>
                              </div>
                            </div>
                          </MDBCol>
                          <MDBCol lg="4" className="bg-grey">
                            <div className="p-5">
                              <MDBTypography tag="h3" className="fw-bold mb-5 mt-2 pt-1">
                                Cart Summary
                              </MDBTypography>

                              <hr className="my-4" />

                              <div className="d-flex justify-content-between mb-4">
                                <MDBTypography tag="h5" className="text-uppercase">
                                  {totalItems} items
                                </MDBTypography>
                                <MDBTypography tag="h5">$ {subtotalPrice.toFixed(2)}</MDBTypography>
                              </div>

                              <MDBTypography tag="h5" className="text-uppercase mb-3">
                                Shipping method
                              </MDBTypography>

                              <div className="mb-4 pb-2">
                                <select id="shipping-opt" className="select p-2 rounded bg-grey" style={{ width: "100%" }} onChange={setTotalPriceHandler}>
                                  <option value="9.99">Standard Delivery - $9.99</option>
                                </select>
                              </div>

                              <MDBTypography tag="h5" className="text-uppercase mb-3">
                                GiFT code
                              </MDBTypography>

                              <div className="mb-5">
                                <MDBInput size="lg" label="Enter your code" />
                              </div>

                              <hr className="my-4" />

                              <div className="d-flex justify-content-between mb-5">
                                <MDBTypography tag="h5" className="text-uppercase">
                                  Total price
                                </MDBTypography>
                                <MDBTypography tag="h5">$ {totalPrice.toFixed(2)}</MDBTypography>
                              </div>

                              <MDBBtn style={{ color: "white" }} block size="lg" onClick={handleToCheckOut} disabled={!(shoppingCartItems.length > 0)}>
                                Proceed to checkout
                              </MDBBtn>
                            </div>
                          </MDBCol>
                        </MDBRow>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
              : !auth ? <UnauthorizedMsg />
              : (emptyCart && auth) ? <EmptyCartMessage />
              : <Loading />
          }
        </section>
      </div>
    </div>
  );
}