import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import ProductFullView from './ProductFullView';
import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

class ProductWithParamsClass extends React.Component {

  render() {

    const { product, addToCartProductsHandler, isLogued, goToSignIn } = this.props
    return (
        <div className='main-product-container'>
          {
            (!isLogued && !(this.props.product && Object.keys(product).length > 0)) && 
            <div style={{ backgroundColor: "#f9f9f9", marginTop: "25vh", padding: "20px", border: "1px solid #ddd", borderRadius: "12px", textAlign: "center" }}>
              <p style={{ fontSize: "18px", color: "#333" }}>You must be a registered user to view this content.</p>
              <button onClick={goToSignIn} style={{ backgroundColor: "#00BBF9", borderRadius: "12px", marginTop: "15px", marginBottom: "20px", width: '80%', color: "#fff", border: "none", padding: "10px 20px", fontSize: "18px", fontWeight: "bold", cursor: "pointer", marginRight: "10px" }}>Sign In</button>
              <p><a href='/signup'>Don't have an account? Sign Up</a></p>
            </div>

          }
          {
            this.props.product && Object.keys(product).length > 0 ? <ProductFullView product={product} addToCartProductsHandler={addToCartProductsHandler} /> : null
          }
        </div>
        
      )
  }
}

function ProductWithParams(props) {

  const [state, setState] = useState({
    product: {},
    isLogued: true,
  });

  const { addToCartProductsHandler } = props

  const navigate = useNavigate();

  const location = useLocation()

  const getProduct = useCallback((name) => {
    fetch(`https://ddannytech-https.ddns.net:4001/product/${name}`, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      },
      mode: 'cors',
    })
    .then((response) => {
      if (response && response.ok) {
        return response.json().then((data) => {
          setState(prevState => ({ ...prevState, product: data[0], isLogued: true }));
        })
      }
      if ((response.status === 401 || response.statusText === 'Unauthorized' || response.status === 403 || response.statusText === 'Forbidden') && window.location.pathname !== '/signup' &&  window.location.pathname !== '/emailVerification') {
        setState((prevState) => ({ ...prevState, isLogued: false}))        
      }
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }, [navigate]);

  const goToSignIn = () => {
    navigate('/signin')
  }

  const { productName } = useParams();

  useEffect(() => {
    getProduct(productName);
  }, [productName, getProduct]);

  useEffect(() => {
    const targetHeight = window.innerHeight * 0.01;
    //const targetHeightBottom = window.innerHeight * 0.1;
    window.scrollTo({top: targetHeight, behavior: 'smooth'});
    //window.scrollTo({bottom: targetHeightBottom})
  }, [])

  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProductWithParamsClass productName={productName} isLogued={state.isLogued} product={state.product} addToCartProductsHandler={addToCartProductsHandler} goToSignIn={goToSignIn} />
        }
      />
    </Routes>
  );
}

export default ProductWithParams;


