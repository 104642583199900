import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { red } from '@mui/material/colors';

const ColorButtonWish = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(red[500]),
  backgroundColor: '#ff0000',
  fontSize: 20,
  borderRadius: 18,
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: red[900],
  },
}));

export default function WishListBtn({ onClick, loadingAddToCart, index, handleDeleteWishListItem }) {
  return (
    <Stack spacing={2} direction="row">
      <ColorButtonWish variant="contained" onClick={onClick}>
        <i class="bi bi-trash-fill"></i>
      </ColorButtonWish>
    </Stack>
  );
}