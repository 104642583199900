import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';

function Info({ totalPrice, shoppingCartItems }) {

  return (
    <React.Fragment>
      <Typography variant="subtitle2" color="text.secondary">
        Total
      </Typography>
      <Typography variant="h4" gutterBottom color={'green'}>
        ${totalPrice.toFixed(2)}
      </Typography>
      <List disablePadding style={{ marginBottom: "40px" }}>
        {shoppingCartItems && (
          shoppingCartItems.map((product) => (
            <ListItem key={product.name.toUpperCase()} sx={{ py: 1, px: 0 }}>
              <ListItemText
                sx={{ mr: 2, width: '250px' }}
                primary={product.name.toUpperCase()}
                secondary={product.brand.toUpperCase()}
              />
              <Box sx={{ justifyContent: "start", width: '60px' }}>
                <Typography variant="body1" fontWeight="medium">
                  {"$" + product.price.toFixed(2)}
                </Typography>
                <Typography variant="body2">
                  {`${product.quantity} Items`}
                </Typography>
              </Box>
            </ListItem>
          )))}
      </List>
    </React.Fragment>
  );
}

Info.propTypes = {
  totalPrice: PropTypes.string.isRequired,
};

export default Info;

