import * as React from 'react';
import { NavLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import '../styles/MenuBarBtn.css'
import '../styles/MenuBtnAnim.css'

export default function MenuListComposition(props) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [btnAnim, setBtnAnim] = React.useState('hamburger hamburger--spin');
  const menuItems = ['Vegan', 'Dairy', 'Logout'];

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const changeAnim = () => {
    if (btnAnim === 'hamburger hamburger--spin') {
      setBtnAnim('hamburger hamburger--spin is-active');
    } else {
      setBtnAnim('hamburger hamburger--spin');
    }
  };

  return (
    <Stack direction="row" spacing={2}>
      <div id='menu-bar-hamburger'>
        <Button
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? 'composition-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={
            handleToggle
          }
          className={btnAnim}
          type="button"
          aria-label='menu'
        >
          <button className={btnAnim} onClick={changeAnim} >
            <span className="hamburger-box">
              <span className="hamburger-inner"
                style={{ backgroundColor: btnAnim === 'hamburger hamburger--spin is-active' ? '#ff0000' : '#6495ed' }}
              ></span>
            </span>
          </button>
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-end"
          transition
          disablePortal
          style={{ width: "40%" }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-end' ? 'left top' : 'left bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                    style={{ zIndex: 999 }}
                  >
                    {menuItems.map((item, index) => (
                      <MenuItem
                        onClick={(e) => {
                          handleClose(e)
                          changeAnim()
                        }}
                        style={{ color: props.selected === index ? '#19e619' : '#000000', fontSize: '30px', fontWeight: 'bold' }}
                      >
                        <NavLink to={ item === 'Logout' ? '/signin' : "/" + item} style={{textDecoration: 'none', color: '#ad4'}} onClick={() => item === 'Logout' && props.handleLogoutSession()} >
                          {item}
                        </NavLink>
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </Stack>
  );
}
