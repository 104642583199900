import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { blue } from '@mui/material/colors';

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(blue[500]),
  backgroundColor: blue[700],
  fontSize: '1.5rem',
  borderRadius: 0,
  borderTopRightRadius: 18,
  borderBottomRightRadius: 18,
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: blue[900],
    
  },
}));

export default function CustomizedButtons({onClick}) {
  return (
    <Stack spacing={2} direction="row">
      <ColorButton variant="contained" disableElevation onClick={onClick} >
        Add to cart
      </ColorButton>
    </Stack>
  );
}