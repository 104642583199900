import React, { useEffect, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  MDBCard,
  MDBCardBody,
  MDBCardFooter,
  MDBCardHeader,
  MDBCardImage,
  MDBCol,
  MDBContainer,
  MDBProgress,
  MDBProgressBar,
  MDBRow,
  MDBTypography,
} from "mdb-react-ui-kit";
import '../styles/ViewOrders.css'
import Loading from './Loading';

const UnauthorizedMsg = () => {
  const navigate = useNavigate()
  return (
    <div className="Unauthorized-msg-container" style={{ height: '100vh', width: '100%', display: 'flex', justifyContent: "center", alignItems: "center" }}>
      <div style={{ backgroundColor: "#f9f9f9", padding: "20px", border: "1px solid #ddd", borderRadius: "12px", textAlign: "center" }}>
      <p style={{ fontSize: "18px", color: "#333" }}>You must be logged in to view your placed orders.</p>
      <p style={{ fontSize: "16px", color: "#666" }}>Please sign in to access your orders history:</p>
      <button onClick={() => navigate('/signin')} style={{ backgroundColor: "#00BBF9", borderRadius: "12px", marginTop: "15px", marginBottom: "20px", width: '80%', color: "#fff", border: "none", padding: "10px 20px", fontSize: "18px", fontWeight: "bold", cursor: "pointer", marginRight: "10px" }}>Sign In</button>
      <p><a href='/signup'>Don't have an account? Sign Up</a></p>
    </div>
    </div>
  )
}

function OrdersComponent({getCartItems, auth}) {
  const [orders, setOrders] = useState([])
  const [subtotalPrice, setSubtotalPrice] = useState([]);
  const [showDetails, setShowDestails] = useState([]);
  
  const token = localStorage.getItem('token');

  const handleSetTotalPrice = () => {
    if (orders.length > 0) {
      let totals = orders.map(array =>
        array.reduce((total, item) => Number(total + (item.price * item.quantity)), 0)
      );
      setSubtotalPrice(totals)
    }
  }

  const handleShowDetails = () => {
    if (orders.length > 0) {
      let arr = orders.map(array => false);
      setShowDestails(arr)
    }
  }

  const getOrders = useCallback(() => {
    fetch('https://ddannytech-https.ddns.net:4001/show-orders', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${token}`
      },
      mode: 'cors',
    }).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json()
    })
      .then(data => {
        setOrders(data);
        handleSetTotalPrice()
      })
      .catch(error => {
        console.error('There has been a problem with your fetch operation:', error);
      });
  }, [token]);

  const toggleShowDetails = (index) => {
    if (showDetails.length > 0) {
      let arr = [...showDetails]
      arr[index] = !arr[index]
      setShowDestails(arr)
      const elemento = document.getElementById(`${orders[index][0].orderNumber}`);
      if (elemento) {
        const y = elemento.getBoundingClientRect().top + window.pageYOffset - window.innerHeight * 0.16;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }
  };

  useEffect(() => {
    getOrders()
    handleSetTotalPrice()
    handleShowDetails()
    getCartItems()
  }, [])

  useEffect(() => {
    handleSetTotalPrice()
    handleShowDetails()
  }, [orders])

  const fecha = new Date()

  return (
    <div style={{ minHeight: "100vh", backgroundColor: "#eee" }}>
      { orders.length > 0 &&
        <div style={{ paddingTop: "20vh", width: "100%", textAlign: "center" }}>
          <h1>Order History</h1>
        </div>
      }
      {
        orders.length > 0 ?
          <section className="h-100 gradient-custom" style={{ backgroundColor: "#eee", minHeight: '100vh', paddingTop: "5vh" }}>
            {
              orders.map((innerArray, index) => (
                <MDBContainer className="py-3 h-100">
                  <MDBRow className="justify-content-center align-items-center h-100">
                    <MDBCol lg="10" xl="8">
                      <MDBCard style={{ borderRadius: "10px" }}>
                        <MDBCardHeader className="px-3 py-4" style={{ backgroundColor: "#6c757d", color: "#fff", }} id={innerArray[0].orderNumber}>
                          <div className="d-flex justify-content-between pt-2">
                            <p className="fw-bold mb-0">Order Details:</p>
                            <p className="mb-0 d-flex" style={{ width: "30%", justifyContent: "flex-end", }}>
                              <span className="fw-bold" style={{ display: "flex", minWidth: "60%", justifyContent: "flex-end", marginRight: "6px", textAlign: 'end' }}>Total</span>
                              <span style={{ display: "flex", minWidth: "40%", justifyContent: "flex-start", marginLeft: "6px" }}>${subtotalPrice.length && subtotalPrice[index].toFixed(2)}</span>
                            </p>
                          </div>

                          <div className="d-flex justify-content-between pt-2">
                            <p className="mb-0">Order Number : {innerArray[0].orderNumber}</p>
                            <p className="mb-0 d-flex" style={{ width: "30%", justifyContent: "flex-end", }}>
                              <span className="fw-bold" style={{ display: "flex", minWidth: "60%", justifyContent: "flex-end", marginRight: "6px", textAlign: 'end' }}>Delivery Charges</span>
                              <span style={{ display: "flex", minWidth: "40%", justifyContent: "flex-start", marginLeft: "6px", alignItems: 'center' }}>$9.99</span>
                            </p>
                          </div>

                          <div className="d-flex justify-content-between pt-2">
                            <p className="mb-0">
                              Invoice Date : {new Date(innerArray[0].orderDate).toLocaleDateString('en-US')}
                            </p>
                            <p className="mb-0 d-flex" style={{ width: "30%", justifyContent: "flex-end", }}>
                              <span className="fw-bold" style={{ display: "flex", minWidth: "60%", justifyContent: "flex-end", marginRight: "6px", textAlign: 'end' }}>Total paid</span>
                              <span style={{ display: "flex", minWidth: "40%", justifyContent: "flex-start", marginLeft: "6px" }}>${subtotalPrice.length && (subtotalPrice[index] + 9.99).toFixed(2)}</span>
                            </p>
                          </div>
                        </MDBCardHeader>
                        <MDBCardBody className="" style={showDetails[index] ? { padding: 0, margin: 0, marginTop: "30px" } : { padding: 0, margin: 0 }}>
                          {showDetails[index] && innerArray.map((item, index) => (
                            <MDBCard className="shadow-0 border mb-4" style={{ marginLeft: '25px', marginRight: '25px' }}>
                              <MDBCardBody>
                                <MDBRow>
                                  <MDBCol md="2" className="d-flex justify-content-center align-items-center">
                                    <MDBCardImage src={item.image} fluid alt={item.name} />
                                  </MDBCol>
                                  <MDBCol md="2" className="text-center d-flex justify-content-center align-items-center">
                                    <p className="text-muted mb-0">{item.name.toUpperCase()}</p>
                                  </MDBCol>
                                  <MDBCol md="2" className="text-center d-flex justify-content-center align-items-center">
                                    <p className="text-muted mb-0 small">{item.brand.toUpperCase()}</p>
                                  </MDBCol>
                                  <MDBCol md="2" className="text-center d-flex justify-content-center align-items-center">
                                    <p className="text-muted mb-0 small">Qty: {item.quantity}</p>
                                  </MDBCol>
                                  <MDBCol md="2" className="text-center d-flex justify-content-center align-items-center">
                                    <p className="text-muted mb-0 small">Paid: ${(item.price * item.quantity).toFixed(2)}</p>
                                  </MDBCol>
                                </MDBRow>
                                <hr className="mb-4" style={{ backgroundColor: "#e0e0e0", opacity: 1 }} />
                                <MDBRow className="align-items-center">
                                  <MDBCol md="2">
                                    <p className="text-muted mb-0 small">Track Order</p>
                                  </MDBCol>
                                  <MDBCol md="10">
                                    <MDBProgress style={{ height: "6px", borderRadius: "16px" }}>
                                      <MDBProgressBar style={{ borderRadius: "16px", backgroundColor: "#4caf50", }}
                                        width={(50*(fecha - (new Date(item.orderDate).setHours(fecha.getHours() - 4)))/(1000 * 60 * 60 * 24)).toFixed()} 
                                        valuemin={0} valuemax={100} />
                                    </MDBProgress>
                                    <div className="d-flex justify-content-around mb-1">
                                      <p className="text-muted mt-1 mb-0 small ms-xl-5">
                                        {(50*(fecha - (new Date(item.orderDate).setHours(fecha.getHours() - 4)))/(1000 * 60 * 60 * 24)).toFixed() > 49 && <i class="bi bi-check-lg" style={{ color: "green"}}></i>} Out for delivary
                                      </p>
                                      <p className="text-muted mt-1 mb-0 small ms-xl-5">
                                      {(50*(fecha - (new Date(item.orderDate).setHours(fecha.getHours() - 4)))/(1000 * 60 * 60 * 24)).toFixed() === 100 && <i class="bi bi-check-lg" style={{ color: "green"}}></i>} Delivered
                                      </p>
                                    </div>
                                  </MDBCol>
                                </MDBRow>
                              </MDBCardBody>
                            </MDBCard>
                          ))}
                        </MDBCardBody>

                        <MDBCardFooter className="border-0 px-2 py-3" style={{ backgroundColor: "#616161", borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                          <MDBTypography style={{ color: "#fff", fontSize: "1.2rem", margin: 0, padding: 0 }}>
                            <p style={{ margin: 0, padding: 0 }}>ETA: {new Date(innerArray[index].orderDate).toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true } )}</p>
                            <p style={{ margin: 0, padding: 0 }}>{(50*(fecha - (new Date(innerArray[index].orderDate).setHours(fecha.getHours() - 4)))/(1000 * 60 * 60 * 24)).toFixed() === 100 ? "Delivered" : "In progress" }</p>
                          </MDBTypography>
                          <MDBTypography tag="h5" className="d-flex align-items-center justify-content-end text-white text-uppercase mb-0" style={{ cursor: "pointer", marginRight: "30px" }} onClick={() => toggleShowDetails(index)}>
                            Show details <span style={{ marginLeft: '10px' }}>{showDetails[index] ? <i class="bi bi-caret-up-square"></i> : <i class="bi bi-caret-down-square"></i>}</span>
                          </MDBTypography>
                        </MDBCardFooter>
                      </MDBCard>
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
              ))
            }
          </section> 
          : !auth ? <UnauthorizedMsg /> 
          : auth && orders.length === 0 ? <EmptyOrdersMessage/>
          : <Loading />
      }
    </div>
  );
}

const EmptyOrdersMessage = () => {

  const portrait = {
    width: "80%", border: 'none', padding: '20px', backgroundColor: '#f0f8ff', textAlign: 'center',
  }

  return (
    <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", marginTop: "20vh", borderRadius: '12px' }}>
      <div style={ portrait }>
        <img style={{ width: "30%" }} src='https://i.ibb.co/x8JGSZ7/green-Artboard-1.png'></img>
        <h2 style={{ marginBottom: "50px" }}>
          Your Orders History Is Currently Empty.
        </h2>
        <p style={{ fontSize: '18px', textAlign: 'justify' }}>Dear custumer, you haven’t placed an order yet. Don’t miss out! Explore our products and make your purchase today.</p>
        <p style={{ fontSize: '18px', textAlign: 'justify' }}>Start shopping to place orders!.</p>
      </div>
    </div>
  );
};

export default OrdersComponent;