import React from 'react';

function About() {
  return (
    <div style={{ padding: '10px' }}>
      <h1>About Vegan Market</h1>
      <p>
        Welcome to Vegan Market, your one-stop online shop for vegan and dairy-free foods. We are dedicated to providing you with a wide range of healthy and sustainable food options that are not only good for you, but also for our planet.
      </p>
      <h2>Our Vision</h2>
      <p>
        At Vegan Market, we believe in the power of plant-based diets for human health and environmental sustainability. We understand the challenges of finding high-quality vegan and dairy-free products, and we strive to make these products easily accessible to everyone.
      </p>
      <h2>Our Mission</h2>
      <p>
        At Vegan Market, we work hard to connect the growing sustainable, ethical, and vegan curious population with independent, pioneering, and eco-focused companies. We value conscious living and take pride in offering sustainable solutions to some of the global issues we’re facing today. Providing vegan options to everyone is something we have built our brand on, and continue to do so every single day. We want everyone's experience of our Vegan Markets to be a great one, which is why we’re always gathering feedback from visitors and traders so we can improve in whichever way we need to. We don’t care whether someone considers themselves a vegan or not, we provide options so that everyone can feel empowered about making their own decisions. Our events are not dogmatic or judgmental, but instead provide a fun place to discover new food and more sustainable & ethical products - all produced by pioneering independent businesses.
      </p>
      <h2>Our Products</h2>
      <p>
        Our selection includes everything you need for a balanced and nutritious vegan diet. From fresh fruits and vegetables to whole grains, nuts, seeds, and a variety of dairy-free products, we have got you covered. We are committed to sourcing the best products from trusted suppliers who share our values of health and sustainability.
      </p>
      <p>
        We are constantly updating our product range to include new and exciting products, and we love hearing from our customers. If you have a product suggestion, or if you would like to learn more about our products, please get in touch.
      </p>
      <h2>Our Guarantee</h2>
      <p>
        We work with thousands of small businesses across the country that focus on producing ethical and sustainable products. From vegan fried chicken to handmade, cruelty-free cosmetics, our traders create a huge variety of unique and innovative vegan products.
      </p>
      <p>
        But we are more than just a food store. We are a community of like-minded individuals who believe in the power of food to transform our health and the health of our planet. We provide resources and support to help you on your journey towards a healthier, more sustainable lifestyle.
      </p>
      
      <p>
        Thank you for choosing Vegan Market. We are excited to be a part of your journey towards a healthier, more sustainable lifestyle.
      </p>
    </div>
  );
}

export default About;
