import React, { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import '../styles/MyAccount.css'
import PaymentIcon from '@mui/icons-material/Payment';
import Link from '@mui/material/Link';

const UnauthorizedMsg = () => {
  const navigate = useNavigate()
  return (
    <div className="Unauthorized-msg-container" style={{ height: '100vh', width: '100%', display: 'flex', justifyContent: "center", alignItems: "center" }}>
      <div style={{ backgroundColor: "#f9f9f9", padding: "20px", border: "1px solid #ddd", borderRadius: "12px", textAlign: "center" }}>
        <p style={{ fontSize: "18px", color: "#333" }}>You must be logged in to view your wish list.</p>
        <p style={{ fontSize: "16px", color: "#666" }}>Please sign in to access your wish list:</p>
        <button onClick={() => navigate('/signin')} style={{ backgroundColor: "#00BBF9", borderRadius: "12px", marginTop: "15px", marginBottom: "20px", width: '80%', color: "#fff", border: "none", padding: "10px 20px", fontSize: "18px", fontWeight: "bold", cursor: "pointer", marginRight: "10px" }}>Sign In</button>
        <p><a href='/signup'>Don't have an account? Sign Up</a></p>
      </div>
    </div>
  )
}

function MyAccount() {

  const navigate = useNavigate()

  const checkAuth = useCallback(() => {
    fetch(`https://ddannytech-https.ddns.net:4001/check-auth`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      },
      mode: 'cors',
    })
      .then((response) => {
        if (response.status === 401) {
          return navigate('/signin')
        }
        if (response.status === 403 || response.statusText === 'Forbidden') {
          return navigate('/emailVerification')
        }
        if (response.status === 402) {
          return navigate('/VerificationLoginCode')
        }
        return null
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, [navigate]);

  useEffect(() => {
    checkAuth()
  }, [])

  return (
    <div className='account-main-container' style={{ minHeight: "80vh", marginTop: "17vh" }}>
        <h1 style={{ color: '#008000' }}>My account</h1>
        <div className='account-opt-container'>
          <Link href="/my-account/user-information" className='user-inf-container opt-container' style={{ color: "#000", textDecoration: 'none' }}>
            <i className="bi bi-person-fill"></i>
            <span>User Information</span>
          </Link>
          <Link href="/my-account/shipping-address" className='shipping-address-container opt-container' style={{ color: "#000", textDecoration: 'none' }} >
            <i className="bi bi-geo-alt-fill"></i>
            <span>Shipping Address</span>
          </Link>
          <Link href="/view-orders" className='orders-history opt-container' style={{ color: "#000", textDecoration: 'none' }}>
            <i className="bi bi-clock-history"></i>
            <span>Orders History</span>
          </Link>
          <Link href="/wish-list" className='wish-list-icon-container opt-container' style={{ color: "#000", textDecoration: 'none' }}>
            <i className="bi bi-bag-heart-fill"></i>
            <span>Wish List</span>
          </Link>
          <Link href="/shopping-cart-view" className='shopping-cart-container opt-container' style={{ color: "#000", textDecoration: 'none' }} >
            <i className="bi bi-cart-fill"></i>
            <span>Shopping Cart</span>
          </Link>
          <div className='payment-method-icon-container opt-container'>
            <PaymentIcon className='bi payment-icon' />
            <span>Payment Method</span>
          </div>
        </div>
    </div>
  )
}

export default MyAccount