import React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Link from '@mui/material/Link';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-circular-progressbar/dist/styles.css';

const SuccessfullMsg = ({message}) => {

  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [popupVisible, setPopupVisible] = useState(true);

  const handleScroll = () => {
    const st = window.pageYOffset || document.documentElement.scrollTop;
    if (st > lastScrollTop) {
      setPopupVisible(false);
      toast.dismiss();
    }
    setLastScrollTop(st <= 0 ? 0 : st);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollTop]);

  useEffect(() => {
        toast(({ closeToast }) => (
          <div style={{ textAlign: 'center', color: '#008000' }}>
            <button onClick={() => {return closeToast()}} style={{ color: "red", background: 'none', border: 'none', fontSize: '28px',  position: "absolute", top: "5px", right: "10px" }}><i class="bi bi-x-circle-fill"></i></button>
            <h3>{ message ? message : "Your information was successfully updated"}</h3>
          </div>
        ), {
          autoClose: 20000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          closeButton: false,
          bodyClassName: "successfully-toast-body",
          toastId: "succesfullyToast",
        });

  }, [popupVisible]);

  return (
    <div>
      <ToastContainer />
    </div>
  );
};

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#008000',
    },
    secondary: {
      main: '#6495ed'
    }
  },
});

export default function UserInformation() {
  const [formErrors, setFormErrors] = useState({});
  const [signUpErrors, setSignUpErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [wait, setWait] = useState(false);
  const [userData, setUserData] = useState({});
  const [successfully, setSuccessfully] = useState(false);
  const [message, setMessage] = useState()

  const navigate = useNavigate();

  const token = localStorage.getItem('token')

  const getInformation = () => {
    fetch('https://ddannytech-https.ddns.net:4001/user-information', {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${token}`,
      },
    }).then(response => {
      if (response && response.ok) {
        response.json().then(data => setUserData(data))
      }
    }).catch(error => console.error(error))
  }

  const updateInformation = (body) => {
    fetch('https://ddannytech-https.ddns.net:4001/update-user', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(body)
    }).then(response => {
      if (response && response.ok && response.status === 201) {
        response.json().then(data => {
          console.log({ message: data.message })
          setMessage(data.message)
          setSuccessfully(true)
          setWait(false)
          setTimeout(() => {
            setMessage()
            setSuccessfully(false)
          }, 4000)
        })
      }
      if (response && response.ok && response.status === 200) {
        response.json().then(data => {
          setMessage(data.message)
          setSuccessfully(true)
          setWait(false)
          setTimeout(() => {
            setMessage()
            setSuccessfully(false)
            localStorage.setItem('token', `Bearer ${data.signed}`)
            navigate('/signin')
          }, 4000)
        })
      }
      if (response && response.status === 401) {
        response.text().then(data => setSignUpErrors({message: data}))
        setWait(false)
      }
    }).catch(error => console.error(error))
  }

  const validateField = (name, value) => {
    if (!value && name !== 'newPassword') return `${name.charAt(0).toUpperCase() + name.slice(1)} is required`;

    if (name === 'firstName' || name === 'lastName') {
      const nameRegex = /^[a-zA-Z]+$/;
      if (!nameRegex.test(value)) return `${name.charAt(0).toUpperCase() + name.slice(1)} can only contain letters`;
    }

    if (name === 'email') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value)) return 'Invalid email format';
    }

    if (name === 'username') {
      const usernameRegex = /^[a-zA-Z0-9]+$/;
      if (!usernameRegex.test(value)) return 'Username can only contain letters and alphanumeric characters';
    }

    if (name === 'password') {
      const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
      if (!passwordRegex.test(value)) return 'Password must contain minimum eight characters, at least one letter, one number and one special character';
    }

    if (name === 'newPassword' && value) {
      const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
      if (!passwordRegex.test(value)) return 'Password must contain minimum eight characters, at least one letter, one number and one special character';
    }

    return null;
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;
    setFormErrors({ ...formErrors, [name]: validateField(name, value) });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setWait(true)
    setSignUpErrors({})
    const data = new FormData(event.currentTarget);
    console.log(data);
    for (let name of data.keys()) {
      const error = validateField(name, data.get(name));
      if (error) {
        setFormErrors({ ...formErrors, [name]: error });
        return;
      }
    }
    //setWait(true)
    const body = {
      username: data.get('username'),
      password: data.get('password'),
      firstName: data.get('firstName'),
      lastName: data.get('lastName'),
      newPassword: data.get('newPassword')
    }
    updateInformation(body)
  };

  const checkAuth = useCallback(() => {
    fetch(`https://ddannytech-https.ddns.net:4001/check-auth`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      },
      mode: 'cors',
    })
      .then((response) => {
        if (response && response.ok) {
          return null;
        }
        if (response.status === 403 || response.statusText === 'Forbidden') {
          return navigate('/emailVerification')
        }
        if (response.status === 402) {
          return navigate('/VerificationLoginCode')
        }
        if (response.status === 401) {
          return navigate('/signin')
        }
        return null
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, [navigate]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    checkAuth()
    getInformation()
  }, [])

  return (
    <ThemeProvider theme={defaultTheme}>
      <Typography style={{ marginTop: "17vh", marginLeft: "30px" }}>
        <Link href="/my-account" variant="body2" style={{ color: "#000", textDecoration: 'none' }}>
          <i class="bi bi-arrow-left-short" style={{ fontSize: '1.2rem' }}></i> <span style={{ fontSize: '1.2rem' }}>Return</span>
        </Link>
      </Typography>
      { successfully && <SuccessfullMsg message={message} /> }
      {Object.keys(userData).length > 0 ? <Container component="main" maxWidth="sm" style={{ paddingBottom: "10vh", minHeight: "100vh" }}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component='h1' variant='h4' sx={{ marginBottom: 2, textAlign: 'center', color: 'green' }}>
            User Information
          </Typography>

          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={!!formErrors.firstName}
                  helperText={formErrors && formErrors.firstName}
                  autoComplete="given-name"
                  name="firstName"
                  defaultValue={Object.keys(userData).length > 0 && userData.firstName}
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  onBlur={handleBlur}
                  onChange={handleBlur}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={!!formErrors.lastName}
                  helperText={formErrors && formErrors.lastName}
                  defaultValue={Object.keys(userData).length > 0 && userData.lastName}
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  onBlur={handleBlur}
                  onChange={handleBlur}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={!!formErrors.username}
                  helperText={formErrors && formErrors.username}
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  defaultValue={Object.keys(userData).length > 0 && userData.username}
                  onBlur={handleBlur}
                  onChange={handleBlur}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={!!formErrors.email}
                  helperText={formErrors && formErrors.email}
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  defaultValue={Object.keys(userData).length > 0 && userData.email}
                  onBlur={handleBlur}
                  onChange={handleBlur}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={!!formErrors.password}
                  helperText={formErrors && formErrors.password}
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  autoComplete="current-password"
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onBlur={handleBlur}
                  onChange={handleBlur}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={!!formErrors.newPassword}
                  helperText={formErrors && formErrors.newPassword}
                  fullWidth
                  name="newPassword"
                  label="New Password"
                  type={showPassword ? 'text' : 'password'}
                  id="newPassword"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onBlur={handleBlur}
                  onChange={handleBlur}
                />
              </Grid>
            </Grid>
            {
              signUpErrors.message && <div style={{ color: 'red', marginBottom: '5px', paddingTop: '25px' }}>{signUpErrors.message}</div>
            }
            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 3, mb: 2, backgroundColor: '#1976d2', fontWeight: 'bold', marginTop: "50px" }}
              disabled={wait}
            >
              {wait ? "Saving changes" : "Save Changes"}
            </Button>

          </Box>
        </Box>
      </Container>

        : <div style={{ minHeight: '100vh' }}></div>}
    </ThemeProvider>
  );
}