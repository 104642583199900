import React, { Component, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import '../styles/Header.css';
import MenuBarBtn from './MenuBarBtn';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import logo from '../img/logo.png';
import { useNavigate } from 'react-router-dom';
import InputSearch from './InputSearch';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { Link } from 'react-router-dom';

function AuthMsg(params) {

  const navigate = useNavigate()

  return (
    <div style={{ backgroundColor: "#f9f9f9", borderRadius: "12px", padding: "20px", border: "1px solid #ddd", textAlign: "center" }}>
      <p style={{ fontSize: "18px", color: "#333" }}>You must be logged in to view your user interface.</p>
      <p style={{ fontSize: "16px", color: "#666" }}>Please sign in to access your cart:</p>
      <button onClick={() => navigate('/signin')} style={{ backgroundColor: "#00BBF9", borderRadius: "12px", marginTop: "15px", marginBottom: "20px", width: '80%', color: "#fff", border: "none", padding: "10px 20px", fontSize: "18px", fontWeight: "bold", cursor: "pointer", marginRight: "10px" }}>Sign In</button>
      <p><a href='/signup'>Don't have an account? Sign Up</a></p>
    </div>
  )

}

export default function HeaderNav(props) {
  const [loggedOut, setLoggedOut] = useState(false);
  const [searchPage, setSearchPage] = useState(false)

  const location = useLocation();
  const navigate = useNavigate();

  const [value, setValue] = useState(location.pathname);

  const handleLogout = () => {
    setLoggedOut(true);
    navigate('/signin')
  };
  const handleSearchs = (value) => {
    navigate(`/products/${value}/`)
  }
  const goToOrders = () => {
    navigate('/view-orders')
  }
  const navigateToHome = () => {
    navigate('/')
  }
  const navigateToAccount = () => {
    navigate('/my-account')
  }
  const navigateToWishList = () => {
    navigate('/wish-list')
  }

  const HandleMobileNav = () => {
    setValue(location.pathname);
    console.log(value);
  }

  useEffect(() => {
    if (location.pathname.includes('/products/')) {
      setSearchPage(true)
    }
    else {
      setSearchPage(false)
    }
  }, [location.pathname]);

  useEffect(() => {
    setValue(location.pathname);
  }, [location]);

  if (location.pathname === '/signin' || location.pathname === '/signup' || location.pathname === '/emailVerification' || location.pathname === '/VerificationLoginCode' || location.pathname === '/checkout' || location.pathname === '/reset-password' || location.pathname.includes('/change-password')) {
    return null;
  }

  return <Header {...props} onLogout={handleLogout} handleSearchs={handleSearchs} searchPage={searchPage} goToOrders={goToOrders} navigateToHome={navigateToHome} HandleMobileNav={HandleMobileNav} value={value} navigateToAccount={navigateToAccount} navigateToWishList={navigateToWishList} />;
}

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orientation: window.innerWidth > window.innerHeight ? 'landscape' : 'portrait',
      isSearchVisible: false,
      isLogoutVisible: false,
      viewUserInterface: false,
      lastScrollTop: 0,
      headerVisible: true,
      value: 0
    };
  }

  handleResize = () => {
    this.setState({
      orientation: window.innerWidth > window.innerHeight ? 'landscape' : 'portrait'
    });
  }

  handleScroll = () => {
    const { lastScrollTop } = this.state;
    const st = window.pageYOffset || document.documentElement.scrollTop;
    if (st > lastScrollTop) {
      this.setState({ headerVisible: false });
    } else {
      this.setState({ headerVisible: true });
    }
    this.setState({ lastScrollTop: st <= 0 ? 0 : st });
    if (this.state.isLogoutVisible) {
      this.setState((prevState) => ({ ...prevState, isLogoutVisible: false, viewUserInterface: false }))
    }
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.addEventListener('scroll', this.handleScroll);
  }

  handleSearchClick = () => {
    this.setState(prevState => ({
      isSearchVisible: !prevState.isSearchVisible
    }));
  }

  handleUserClick = () => {
    this.setState(prevState => ({
      viewUserInterface: !prevState.viewUserInterface
    }));
  }

  handleLogoutClick = () => {
    this.setState(prevState => ({
      isLogoutVisible: !prevState.isLogoutVisible
    }));
  }

  handleLogoutSession = () => {
    localStorage.removeItem('token');
    this.props.onLogout();
  };

  showCartHandler = () => {
    this.setState(prevState => ({
      viewUserInterface: false
    }));
    this.setState(prevState => ({
      isLogoutVisible: false
    }), () => this.props.toggleShoppingCart());
  }

  goToOrdersHandler = () => {
    this.setState(prevState => ({
      viewUserInterface: false
    }));
    this.setState(prevState => ({
      isLogoutVisible: false
    }), () => this.props.goToOrders());
  }

  goToAccountHandler = () => {
    this.setState(prevState => ({
      viewUserInterface: false
    }));
    this.setState(prevState => ({
      isLogoutVisible: false
    }), () => this.props.navigateToAccount());
  }

  goToWishListHandler = () => {
    this.setState(prevState => ({
      viewUserInterface: false
    }));
    this.setState(prevState => ({
      isLogoutVisible: false
    }), () => this.props.navigateToWishList());
  }


  render() {
    const { selected, showProductsHandler, auth, showShoppingCart, cartItemsQty, handleSearchs, searchPage, navigateToHome, value, HandleMobileNav } = this.props;
    const menuItems = ['Home', 'Vegan', 'Dairy'];

    const handlerFunction = () => {
      showProductsHandler()
    }

    return (
      <div className='header-main-container' >
        {
          this.state.orientation === 'landscape' && window.innerWidth > 800 ?
            <div className={`header-container ${this.state.headerVisible ? '' : !showShoppingCart && 'hidden'}`}>
              <div className='logo-container'>
                <img src={logo} onClick={navigateToHome} alt='Market Logo' />
                <p className='market-name-landscape'><a href='/' style={{ textDecoration: 'none', color: "#ad4" }}>Vegan Market</a></p>
              </div>
              <div className='menu-opt-container'>
                <ul>
                  {menuItems.map((item, index) => (
                    <li
                      key={index}
                      onClick={() => handlerFunction(index)}
                    >
                      <NavLink to={item === "Home" ? '/' : "/" + item} >
                        {item}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>
              <div className='header-icons-container'>
                <div className={`search-input ${this.state.isSearchVisible ? 'visible' : ''}`}>
                  <input name='search' id='search' type='text'
                    style={{
                      width: '98%',
                      margin: '5px',
                      border: '1px solid #ccc',
                      borderRadius: '8px',
                      padding: '5px',
                      fontSize: '1rem'
                    }}
                    placeholder="Search for products you desire..."
                    onKeyDown={(event) => {
                      let search = document.getElementById('search')
                      if (event.key === 'Enter' && search.value !== '') {
                        this.setState({ isSearchVisible: '' })
                        handleSearchs(search.value);
                      }
                    }} />
                </div>
                <div className='header-icons'>
                  <i className="bi bi-search" onClick={this.handleSearchClick} />
                  <div className='cart-items-main-container' style={{ position: "relative" }} onClick={this.showCartHandler}>
                    <i className="bi bi-cart-fill" style={{ fontSize: '2.2rem' }} />
                    <span className='cart-qty-container'
                      style={{
                        position: "absolute",
                        top: "46%",
                        left: "58%",
                        margin: 0,
                        transform: "translate(-50%, -50%)",
                        color: "#fff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "bold",
                        cursor: "pointer",
                        fontSize: '0.9rem'
                      }}>{cartItemsQty === 0 ? '' : cartItemsQty < 100 ? cartItemsQty : "99+"}</span>
                  </div>
                  <i className="bi bi-person-circle"
                    onClick={() => {
                      this.handleUserClick()
                      this.handleLogoutClick()
                    }}></i>

                </div>
              </div>
            </div>
            : <div className={`header-container-portrait ${this.state.headerVisible ? '' : !showShoppingCart && 'hidden'}`}>
              <div className='logo-container-portrait'>
                <img className='logo-portrait' src={logo} alt='Market Logo' />
              </div>

              <div className='mobile-search-container' style={{ height: "100%", width: "80%", display: "flex", alignItems: "flex-end", justifyContent: "center", }}>
                <InputSearch />
              </div>

            </div>}
        {(this.state.isLogoutVisible && auth) ?
          <div className={`user-interface-opts ${this.state.viewUserInterface ? 'show' : ''}`}>
            <div className='go-to-orders-container' style={{ fontSize: "1.5rem", cursor: "pointer", paddingBottom: '8px' }} onClick={() => this.goToOrdersHandler()}>
              <i class="bi bi-card-list" style={{ color: "#6495ed" }}></i> View Orders
            </div>
            <div className='go-to-wish-list-container' style={{ fontSize: "1.5rem", cursor: "pointer", paddingBottom: '8px' }} onClick={() => this.goToWishListHandler()}>
              <i class="bi bi-bag-heart" style={{ color: "#6495ed" }}></i> Wish List
            </div>
            <div className='go-to-account-container' style={{ fontSize: "1.5rem", cursor: "pointer", paddingBottom: '8px' }} onClick={() => this.goToAccountHandler()}>
              <i class="bi bi-person-fill-gear" style={{ color: "#6495ed" }}></i> Account
            </div>
            <div className='logout-container' onClick={() => { this.handleLogoutSession() }}>
              <i class="bi bi-box-arrow-right"></i> Logout
            </div>
          </div> : this.state.isLogoutVisible &&
          <div className={`user-interface-auth ${this.state.viewUserInterface ? 'show' : ''}`}>
            <AuthMsg />
          </div>

        }
        {(this.state.orientation === 'portrait' || window.innerWidth <= 800) &&
          <div style={{
            position: 'fixed',
            bottom: '0',
            width: '100%',
            zIndex: '9999',
            backgroundColor: '#f0f8ff',
            height: '6.5vh',
            margin: 0,
            border: '1px solid #808080'
          }}>
            <ul style={{ width: '100%', listStyle: 'none', height: "100%", display: 'flex', alignItems: 'flex-end', fontSize: "1.9rem", color: "#6495ed", justifyContent: 'space-around' }}>
              <BottomNavigation
                style={{ background: 'none', width: '80%', color: "#6495ed", margin: 0, padding: 0, height: '90%' }}
                value={value}
                onChange={() => {
                  HandleMobileNav()
                  console.log(value);
                }}
              >
                <BottomNavigationAction
                  icon={<i class="bi bi-house-door-fill" />}
                  component={Link}
                  value="/"
                  to="/"
                />
                <BottomNavigationAction
                  icon={
                    <div className='cart-items-main-container' style={{ position: "relative" }}>
                      <i className="bi bi-cart-fill" style={{ fontSize: '2.2rem' }} />
                      <span className='cart-qty-container'
                        style={{
                          position: "absolute",
                          top: "51%",
                          left: "58%",
                          margin: 0,
                          transform: "translate(-50%, -50%)",
                          color: "#fff",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontWeight: "bold",
                          cursor: "pointer",
                          fontSize: '0.8rem'
                        }}>{cartItemsQty < 100 ? cartItemsQty : "99+"}</span>
                    </div>
                  }
                  component={Link}
                  value="/shopping-cart-view"
                  to="/shopping-cart-view"
                />
                <BottomNavigationAction
                  icon={<i class="bi bi-bag-heart"></i>}
                  component={Link}
                  value="/wish-list"
                  to="/wish-list"
                />
                <BottomNavigationAction
                  icon={<i class="bi bi-person-fill"></i>}
                  component={Link}
                  value="/my-account"
                  to="/my-account"
                />
              </BottomNavigation>
              <MenuBarBtn
                selected={selected}
                showProductsHandler={showProductsHandler}
                handleLogoutSession={this.handleLogoutSession}
              />
            </ul>
          </div>}
        <div className={`fake-store-msg ${this.state.headerVisible ? '' : !showShoppingCart && 'hidden'}`}>
          <span>
            <strong>Disclaimer:</strong> Please note that this online store is a <strong>simulation</strong> and is <strong>not real</strong>. It was created solely for the purpose of demonstrating the programming and web development skills of the developer. No real products are available, and no real transactions will take place. Thank you for your understanding.
          </span>
        </div>
        {searchPage &&
          <div className={`search-result ${this.state.headerVisible ? '' : !showShoppingCart && 'hidden'}`}>
            <p>Product(s) found matching the search criteria.</p>
          </div>
        }
      </div>
    )
  }
}
