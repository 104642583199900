import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-toastify/dist/ReactToastify.css';
import 'react-circular-progressbar/dist/styles.css';
import '../styles/WelcomePopupRegister.css'

const WelcomePopupRegister = () => {

  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [popupVisible, setPopupVisible] = useState(true);

  const handleScroll = () => {
    const st = window.pageYOffset || document.documentElement.scrollTop;
    if (st > lastScrollTop) {
      setPopupVisible(false);
      toast.dismiss();
      if (st > 150) {
        localStorage.setItem('welcomeRegistration', false)
      }
    } else {
      if (localStorage.getItem('welcomeRegistration')) {
        setPopupVisible(true);
      }
    }
    setLastScrollTop(st <= 0 ? 0 : st);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollTop]);

  useEffect(() => {
    if (popupVisible && localStorage.getItem('welcomeRegistration') == 'true') {
        toast(({ closeToast }) => (
          <div>
            <button onClick={() => { localStorage.setItem('welcomeRegistration', false); return closeToast()}} style={{ color: "red", background: 'none', border: 'none', fontSize: '28px',  position: "absolute", top: "5px", right: "10px" }}><i class="bi bi-x-circle-fill"></i></button>
            <h3>Welcome to our online vegan food store! 🌱</h3>

            We’re thrilled to have you here! 🎉<br/>
            <br/>
            You’ve just opened up a world of delicious, healthy, and sustainable food options right at your fingertips. As a member, you’ll enjoy exclusive deals, early access to our new products, and tips on vegan recipes and lifestyle.
            <br/>
            <br/>
            Remember, every meal you choose from our store isn’t just good for you, it’s also contributing to a healthier planet. 🌍
            <br/>
            <br/>
            Happy shopping and enjoy your journey towards health and sustainability with us!
            <br/>
            <br/>
            Best, The Vegan Food Store Team.
          </div>
        ), {
          autoClose: 20000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          closeButton: false,
          bodyClassName: "toast-body",
          toastId: "welcomeToast",
        });

        //return localStorage.setItem('welcomeRegistration', false)
      
    }
  }, [popupVisible]);


  return (
    <div>
      <ToastContainer />
    </div>
  );
};

export default WelcomePopupRegister;
