import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import passwordResetImg from '../img/password-reset-banner.jpg'
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import PasswordIcon from '@mui/icons-material/Password';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit">
        Fake Vegan Market
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const defaultTheme = createTheme();

export default function ChangePassword() {
  const [error, setError] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [wait, setWait] = useState(false);

  const navigate = useNavigate();
  const location = useLocation()

  const validateField = (name, value) => {
    if (!value && name === 'newPassword') {
      return 'The new password is required'
    }

    if (!value && name === 'newPasswordConf') {
      return 'The confirmation password is required'
    }

    if (name === 'newPassword') {
      const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
      if (!passwordRegex.test(value)) return 'Password must contain minimum eight characters, at least one letter, one number and one special character';
    }

    if (name === 'newPasswordConf') {
      const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
      if (!passwordRegex.test(value)) return 'Password must contain minimum eight characters, at least one letter, one number and one special character';
    }

    return null;
  };


  const changePasswordRequest = async (body) => {
    console.log(body);
    try {
        const response = await fetch(`https://ddannytech-https.ddns.net:4001/change-password/${location.pathname.split('/')[1]}`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            body: JSON.stringify(body)
        });

        if (response.ok) {
            const data = await response.text();
            localStorage.removeItem('token');
            localStorage.setItem('token', data);
            navigate('/');
            setWait(false);
        } else {
            const data = await response.json();
            setWait(false);
            setError(data.message);
        }
    } catch (error) {
        console.error(error);
        setWait(false);
        setError(error);
    }
}


  const handleBlur = (event) => {
    const { name, value } = event.target;
    setFormErrors({ ...formErrors, [name]: validateField(name, value) });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setWait(true)
    setError('')
    const data = new FormData(event.currentTarget);
    for (let name of data.keys()) {
      const error = validateField(name, data.get(name));
      if (error) {
        setFormErrors({ ...formErrors, [name]: error });
        setWait(false)
        return;
      }
    }
    if (data.get('newPassword') !== data.get('newPasswordConf')) {
      setFormErrors({ ...formErrors, ['newPasswordConf']: 'Please ensure that your confirmation password matches your new password. This is to verify that there are no typographical errors in your new password. Thank you for your attention to this matter.' });
      setWait(false)
      return;
    }
    const body = {
      newPassword: data.get('newPassword')
    }
    changePasswordRequest(body)
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7}
          sx={{
            backgroundImage: `url(${passwordResetImg})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box sx={{ my: 8, mx: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <PasswordIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Change Password
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                error={!!formErrors.newPassword}
                helperText={formErrors && formErrors.newPassword}
                required
                fullWidth
                name="newPassword"
                label="New Password"
                type={showPassword ? 'text' : 'password'}
                id="password"
                autoComplete="new-password"
                autoFocus
                onBlur={handleBlur}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                margin="normal"
                error={!!formErrors.newPasswordConf}
                helperText={formErrors && formErrors.newPasswordConf}
                required
                fullWidth
                name="newPasswordConf"
                label="Confirm New Password"
                type={showPassword ? 'text' : 'password'}
                id="password"
                autoComplete="new-password"
                onBlur={handleBlur}
                onChange={handleBlur}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              {error && <div style={{ color: "red" }}>{error}</div>}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={wait}
              >
                {wait ? "Changing Password..." : "Change Password"}
              </Button>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}